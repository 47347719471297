import { ReactElement, useEffect, useState } from 'react';

import { LabelledInformationProposal } from '../LabelledInformationProposal';

type Props = {
  data: {
    nomePlano: string;
    quantidadeVidas: string;
    valorPagamento: string;
  };
};

export function PaymentSummaryProposal(state: Props): ReactElement {
  const [anual, setAnual] = useState(false);
  const valor = state.data.valorPagamento.substring(2).replace(',', '.');
  const valorAnual = (Math.round(parseFloat(valor) * 1200) / 100).toFixed(2);
  const txValorAnual = `R$ ${valorAnual.replace('.', ',')}`;
  useEffect(() => {
    const existe = new RegExp(`\\bAnual\\b`, 'i');
    setAnual(existe.test(state.data.nomePlano));
  }, [state]);
  return (
    <section className="p-4 h-full rounded-xl w-full border border-[#D8D8D8] flex max-[748px]:flex-col justify-between gap-6">
      <div className="flex flex-col max-w-[347px]">
        <h2 className="text-xl text-[#0B0B0B] mb-4">Resumo da compra</h2>
        <ul className="flex flex-wrap w-full gap-x-8 gap-y-3">
          <LabelledInformationProposal
            label="PLano escolhido"
            value={state.data.nomePlano}
          />
          <LabelledInformationProposal
            label="QTD de vidas"
            value={state.data.quantidadeVidas}
          />
          {anual ? (
            <LabelledInformationProposal
              label="Valor plano"
              value={txValorAnual}
            />
          ) : (
            <LabelledInformationProposal
              label="Valor plano"
              value={state.data.valorPagamento}
            />
          )}

          {/* <LabelledInformationProposal
            label="Valor Telemedicina"
            value="R$ 3,99"
          /> */}
        </ul>
      </div>
      <div className="max-[748px]:py-3 min-[748px]:max-w-[220px] w-full rounded-xl bg-[#F8F8FB] flex flex-col items-center justify-center gap-3 min-[748px]:min-h-[140px]">
        <label htmlFor="totalValue" className="block text-secondary-main -mb-2">
          Valor total
        </label>
        {anual ? (
          <>
            <span id="totalValue" className="text-[#394146]">
              <strong className="text-[2rem]"> {txValorAnual} </strong>
            </span>
            <span className="-mt-5 text-[#394146]">por ano</span>
          </>
        ) : (
          <>
            <strong className="text-[2rem]">{state.data.valorPagamento}</strong>
            <span className="-mt-5 text-[#394146]">por mês</span>
          </>
        )}
      </div>
    </section>
  );
}
