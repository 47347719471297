import { ReactElement } from 'react';

import { useDentistsContext } from '../';
import { Button } from '../../Button';
import { SearchIcon } from '../../icons/SearchIcon';
import { Select } from '../../Select';
import PlanForm from './PlanForm';
import QuestionBox from './QuestionBox';

export default function SearchStage(): ReactElement {
  const { specialties, specialty, setSpecialty, canSearch, handleSearch } =
    useDentistsContext();
  return (
    <>
      <div className="bg-primary-container2 rounded-lg shadow-card p-5 mt-5">
        <h3 className="text-high-emphasis max-[1300px]:text-lg text-2xl font-normal">
          Responda as perguntas a seguir que irei te ajudar.
        </h3>
        <div className="flex justify-between mt-3 max-[1300px]:flex-col items-center gap-8">
          <QuestionBox />

          <div>
            <p className="text-primary-main text-[1.25rem] font-medium">Ou</p>
          </div>

          <div>
            <h3 className="text-high-emphasis text-2xl max-[1300px]:text-lg mb-5 font-normal">
              Já sei a especialidade que procuro.
            </h3>
            <Select
              label=""
              enablePlaceholder
              options={[]}
              optionsFull={specialties.map(s => ({
                Id: s.idEspecialidade,
                Sigla: s.nomeEspecialidade,
              }))}
              value={specialty?.idEspecialidade}
              onChange={c =>
                setSpecialty(
                  specialties.find(s => s.idEspecialidade === +c.target.value),
                )
              }
              placeholder="Todas"
            />
          </div>
        </div>
      </div>
      <div className="mt-5 p-2 flex justify-between items-end max-[748px]:flex-col max-[748px]:items-center gap-5">
        <PlanForm />
        <Button
          disabled={!canSearch}
          text="Buscar Dentista"
          onClick={handleSearch}
          className={`max-[748px]:w-full disabled:bg-gray-200`}
          Icon={SearchIcon}
        />
      </div>
    </>
  );
}
