import { ReactElement } from 'react';

import BigBanner from '../../components/BigBanner';
import DoubleCard from '../../components/DoubleCard';
import ClipIcon from '../../components/icons/ClipIcon';
import InfoCard from '../../components/InfoCard';
import { NewPersonalPlans } from '../../components/NewPersonalPlans';
import { PageHelmet } from '../../components/PageHelmet';
import QuickAccess from '../../components/QuickAccess';
import SolTextBox from '../../components/SolTextBox';

export default function BeneficiaryPage(): ReactElement {
  return (
    <div className="h-full min-h-[calc(100vh-549px)]">
      <PageHelmet title="Beneficiário | OdontoGroup" />

      <BigBanner title="Beneficiário" page="beneficiary" hideBtn hideText />
      <section>
        <DoubleCard
          className="mx-auto -mt-24"
          title1="Já Sou Cliente"
          text1="Acessar Portal de Beneficiário"
          title2="Ainda não sou Cliente"
          text2="Quero contratar"
          icon1="person"
          icon2="deal"
          link2="#individual"
          blank2
        />
      </section>

      <QuickAccess className="mt-20 mb-32" />

      <section className="flex flex-col gap-5 my-10 min-[1000px]:px-32 max-[999px]:px-5 min:[1000px]:mb-52 max-[999px]:mb-10">
        <div className="bg-primary-main p-8 flex items-center justify-between rounded-xl max-[999px]:flex-col max-[999px]:gap-16">
          <div className="text-primary-container2 max-w-[560px]">
            <h1 className="font-semibold text-[2.625rem] max-[999px]:text-lg max-[999px]:text-center">
              Área do ex-beneficiário
            </h1>
            <p className="text-2xl text-[1.75rem] leading-[2.625rem] max-[999px]:text-base max-[999px]:text-center">
              Encontre os serviços disponíveis para beneficiários inativos
            </p>
          </div>
          {/*  */}
          <div className="flex flex-col gap-3">
            <a
              className="bg-neutral-white rounded-lg p-3.5 flex items-center justify-between gap-3.5"
              href="https://odontogroup.s4e.com.br/sys/?TipoUsuario=1"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/images/icons/bill.svg" />
              <p className="mr-auto text-primary-main text-lg">
                Imposto de Renda
              </p>
              <ClipIcon />
            </a>
            <a
              className="bg-neutral-white rounded-lg p-3.5 flex items-center justify-between gap-3.5"
              href="https://odontogroup.s4e.com.br/sys/?TipoUsuario=1"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/images/icons/mail.svg" />
              <p className="mr-auto text-primary-main text-lg">
                Carta para quitação
              </p>
              <ClipIcon className="ml-3" />
            </a>
          </div>
        </div>
      </section>

      <section className="flex items-center gap-20 mb-36 max-[999px]:flex-col">
        <img src="/assets/images/beneficiary/female.png" />
        <div className="max-w-[763px] max-[999px]:mx-4">
          <h3 className="text-[2.625rem] leading-[3.938rem] text-primary-main font-semibold max-w-[543px] mb-[2rem] max-[999px]:text-lg max-[999px]:max-w-[218px]">
            Queremos fazer você sorrir ainda mais
          </h3>
          <p className="text-2xl text-medium-emphasis max-[999px]:text-sm mb-[10px]">
            Os planos da OdontoGroup não possuem coparticipação, isso significa
            que você paga apenas a mensalidade do seu plano e tem acesso aos
            procedimentos inclusos no ROL de cobertura sem custos adicionais. É
            simples e sem surpresas.
          </p>
          <p className="text-2xl text-medium-emphasis max-[999px]:text-sm mb-[10px]">
            Com uma extensa rede de 32 mil dentistas credenciados em todo o
            país, é altamente provável que o seu dentista de confiança esteja
            entre eles. E se por acaso não estiver, não se preocupe, você pode
            solicitar o credenciamento dele à OdontoGroup.
          </p>
          <p className="text-2xl text-medium-emphasis max-[999px]:text-sm">
            O custo de um tratamento básico em consultório particular, pode
            chegar ao valor de quase dois anos do plano odontológico. Isso não
            só representa uma economia considerável, mas também garante a sua
            tranquilidade de ter mais de 200 procedimentos cobertos.
          </p>
        </div>
      </section>

      <section className="flex flex-col gap-5 my-10 min-[1000px]:px-32 max-[999px]:px-10">
        <h1 className="font-semibold mb-10 text-[2.625rem] leading-[3.938rem] text-primary-main max-w-[625px] my-4 max-[700px]:text-lg max-[1199px]:hidden mx-auto">
          Diferenciais Odontogroup
        </h1>

        <SolTextBox
          title="Serviço de concierge"
          text="Auxiliamos você a marcar consultas e na escolha de tratamentos e especialidades, proporcionando facilidade e comodidade."
          maxTextSize={908}
        />
        <InfoCard
          title="Sem limite de uso"
          text="Use de forma ilimitada os procedimentos que constam no ROL da cobertura do seu plano."
        />
        <InfoCard
          title="Plano Completo"
          text="Cobertura de atendimento clínico além do ROL mínimo da ANS."
        />
        <InfoCard
          title="Emergência 24h"
          text="Atendimento 24h, inclusive em feriados e finais de semana. Consulte disponibilidade."
        />
        <InfoCard
          title="Cobertura nacional"
          text="São mais de 32 mil prestadores em todo o país."
        />
        <InfoCard
          title="Rede de descontos"
          text="Descontos em exames, vacinas, medicamentos e muito mais!"
          extras={
            <div className="flex gap-16 min-[1180px]:ml-10 mt-3 max-[1179px]:grid max-[1179px]:grid-cols-6 max-[1179px]:gap-10">
              <img
                className="max-[1179px]:col-span-6 max-[1179px]:mx-auto"
                src="/assets/images/company-logos/drogasil.png"
              />
              <img
                className="max-[1179px]:col-span-3 max-[1179px]:mx-auto"
                src="/assets/images/company-logos/raia.png"
              />
              <img
                className="max-[1179px]:col-span-3 max-[1179px]:mx-auto"
                src="/assets/images/company-logos/sabin.png"
              />
              <img
                className="max-[1179px]:col-span-3 max-[1179px]:mx-auto"
                src="/assets/images/company-logos/dasa.png"
              />
              <img
                className="max-[1179px]:col-span-3 max-[1179px]:mx-auto"
                src="/assets/images/company-logos/nabuco.png"
              />
            </div>
          }
        />
      </section>

      <section className="min-h-[300px] max-[748px]:block min-[1000px]:text-center my-20">
        <h1 className="text-[2.625rem] text-center text-primary-main font-semibold max-[999px]:text-lg mx-[15%]">
          Baixe agora o App OdontoGroup Associado!
        </h1>

        <div className="justify-between z-1" id="videoid">
          <iframe
            className="w-[360px] min-[530px]:w-[500px] h-[300px] min-[1000px]:w-[900px] min-[1500px]:w-[1200px] min-[1000px]:h-[720px] min-[1000px]:-mb-[25rem] max-[999px]:-mb-[5rem] mx-auto rounded-lg mt-10"
            src="https://www.youtube.com/embed/_MUd-Sltix0"
            title="A GALERA NÃO SABE MAIS USAR PC!"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
        <div className="bg-primary-main min-h-[600px] max-[1000px]:min-h-[400px] flex flex-col items center justify-end">
          <div className="mb-10 text-center w-fit mx-auto">
            <p className="text-neutral-white text-[1.25rem] font-normal my-3">
              Onde Baixar:
            </p>
            <div className="flex max-[748px]:flex-col gap-6 max-[748px]:items-center">
              <a
                className="max-w-[50%]"
                href={process.env.REACT_APP_GOOGLE_PLAY}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/images/google-play-tag.png"
                  alt="Tag GooglePlay"
                />
              </a>
              <a
                className="max-w-[50%]"
                href={process.env.REACT_APP_APPLE_STORE}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/images/app-store-tag.png"
                  alt="Tag AppStore"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="first-access">
        <h1 className="ml-5 max-w-[10rem] mt-28 mb-10 min-[1200px]:hidden text-primary-main font-semibold text-lg">
          Primeiro Acesso?
        </h1>
        <div className="flex min-[1200px]:items-center gap-5 mx-28 max-[999px]:mx-5 max-[999px]:gap-0 max-[999px]:mb-10">
          <img
            src="/assets/images/sol/half-sol.png"
            className="max-[1199px]:hidden"
          />
          <div className="max-w-[730px] max-[1199px]:flex max-[1199px]:flex-col max-[1199px]:items-center">
            <h1 className="font-semibold text-[2.625rem] leading-[3.938rem] text-primary-main max-w-[625px] my-4 max-[700px]:text-lg max-[1199px]:hidden">
              Primeiro acesso?
            </h1>

            <p className="text-medium-emphasis text-2xl mb-10 max-[999px]:text-base">
              Utilize seu CPF como login e, como senha inicial, a primeira letra
              do seu nome seguida pelos três primeiros dígitos do CPF.
            </p>
            <p className="text-medium-emphasis text-2xl max-[999px]:text-base">
              Após o primeiro acesso, será necessário criar uma nova senha que
              contenha pelo menos: um número, um caractere especial, uma letra
              maiúscula e uma letra minúscula. Por exemplo: Odonto24@.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-primary-main" id="individual">
        <div className="text-center text-neutral-with flex flex-col justify-center items-center">
          <h3 className="font-semibold text-[2.625rem] max-[999px]:text-lg max-[999px]:text-center">
            Contrate Agora
          </h3>
          <p className="text-2xl text-[1.75rem] leading-[2.625rem] max-[999px]:text-base max-[999px]:text-center max-w-[1089px]">
            Conheça nossos planos e convênios, escolha a opção que se encaixa
            com o seu perfil. Temos um plano exclusivo para você.
          </p>
        </div>
        <NewPersonalPlans className="-mb-32" />
      </section>
      <section className="bg-neutral-white min-h-[500px]">{/*  */}</section>
    </div>
  );
}
