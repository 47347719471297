import { ReactElement } from 'react';

import { PageHelmet } from '../../components/PageHelmet';
import Banner from './Banner';
import './privacy.css';

export default function PrivacyPage(): ReactElement {
  return (
    <div className="h-full min-h-[calc(100vh-549px)]">
      <PageHelmet title="Política de privacidade | OdontoGroup" />

      <Banner title="Política de privacidade" />

      <div className="container-lg">
        <div className="corpo">
          <p>
            <h2>
              <strong>POLÍTICA DE PRIVACIDADE ODONTOGROUP</strong>
            </h2>
          </p>
          <p>
            Esta Política de Privacidade da OdontoGroup cobre a coleta, uso e
            divulgação de informações pessoais que podem ser coletadas pela
            OdontoGroup a qualquer momento que você interagir conosco, como
            visitar nosso site, adquirir produtos ou serviços, ou entrar em
            contato com a OdontoGroup. A sua privacidade é uma de nossas
            prioridades e não medimos esforços para a protegê-la.
          </p>
          <p>
            Você reconhece que esta Política de Privacidade faz parte dos nossos{' '}
            <strong>Termos de Serviço</strong> e, ao acessar ou usar nosso site
            ou comprar qualquer uma das ofertas associadas, você concorda em
            obedecer a todos os seus termos e condições. Esta Política de
            Privacidade descreve como gerenciamos seus dados pessoais,
            englobando a coleta, uso e divulgação de informações pessoais que
            serão coletadas durante a visitação em quaisquer um de nossos sites,
            a aquisição de quaisquer produto ou serviço nosso e/ou quando fizer
            contato conosco por quaisquer canais digitais por nós ofertado.
          </p>
          <p>
            Leia atentamente este documento para saber como coletamos estas
            informações, incluindo que tipo de informações são coletadas, como
            são usadas, a quem divulgamos e como protegemos suas informações
            pessoais.
            <strong>
              Se você não concorda com estes termos, por favor, não acesse ou
              use este site ou quaisquer um de nossos outros sites.
            </strong>
          </p>
          <p>
            Nós nos reservamos o direito de alterar esta Política de Privacidade
            a qualquer momento. Quaisquer alterações, modificações, acréscimos
            ou exclusões entrarão em vigor imediatamente. Você reconhece e
            concorda que é sua responsabilidade revisar este site e esta
            Política periodicamente e estar ciente de quaisquer modificações.
            Seu uso continuado do site após tais modificações constituirá seu
            reconhecimento da Política modificada e concordância em obedecer e
            ser regido pela Política modificada.
          </p>
          <p>
            <h2>
              <strong>QUE TIPOS DE INFORMAÇÕES SÃO COLETADAS?</strong>
            </h2>
          </p>
          <p>
            A fim de melhor lhe fornecer nossos diversos serviços, coletamos
            dois tipos de informações sobre nossos usuários: Informações de
            Identificação Pessoal e Informações de Identificação Não Pessoal.
            Nosso principal objetivo ao coletar informações sobre você é
            fornecer uma experiência tranquila, eficiente e personalizada ao
            usar nosso site e contratar nossas ofertas de produtos e/ou
            serviços.
          </p>
          <p>
            <strong>Informação pessoalmente identificável:</strong> Refere-se a
            informações que nos permitem saber quem você é. Quando você se
            envolve em certas atividades neste site, como pedido de um produto
            ou serviço, envio de conteúdo e/ou postagem de conteúdo em nosso
            blog, fóruns de discussão ou outras áreas públicas, ou nos enviando
            comentários, podemos pedir que você forneça certas informações sobre
            você, preenchendo e enviando um formulário online. É totalmente
            opcional para você se envolver nessas atividades. Se você decidir se
            envolver nessas atividades, no entanto, podemos pedir que nos
            forneça informações pessoais, como seu nome e sobrenome, endereço
            para correspondência (incluindo código postal), endereço de e-mail e
            outras informações de identificação pessoal. Ao solicitar produtos
            ou serviços no site, você pode ser solicitado a fornecer um número
            de cartão de crédito ou dados bancários. Dependendo da atividade,
            algumas das informações que pedimos que você forneça são
            identificadas como obrigatórias e outras como voluntárias. Se você
            não fornecer os dados obrigatórios com relação a uma atividade
            específica, não poderá participar dessa atividade.
          </p>
          <p>
            <strong>Informações não pessoalmente identificáveis:</strong>{' '}
            Refere-se a informações que não identificam por si só um indivíduo
            específico. Coletamos certas informações sobre você com base em onde
            você visita em nosso site de várias maneiras. Essas informações são
            compiladas e analisadas de forma pessoal e agregada. Essas
            informações podem incluir o Uniform Resource Locator (“URL”) do site
            da Web de onde você acabou de chegar, qual URL você acessa em
            seguida, qual navegador está usando e seu endereço de protocolo de
            Internet (“IP”). Um URL é o endereço global de documentos e outros
            recursos na World Wide Web (WWW). Um endereço IP é um identificador
            para um computador ou dispositivo em uma rede Transmission Control
            Protocol / Internet Protocol (“TCP / IP”), como a World Wide Web.
            Redes como a Web usam o protocolo TCP / IP para rotear informações
            com base no endereço IP do destino. Em outras palavras, um endereço
            IP é um número que é atribuído automaticamente ao seu computador
            sempre que você está navegando na web, permitindo que os servidores
            da web localizem e identifiquem o seu computador. Os computadores
            exigem endereços IP para que os usuários se comuniquem na Internet.
          </p>
          <p>
            <h2>
              <strong>POR QUE ESTA POLÍTICA EXISTE?</strong>
            </h2>
          </p>
          <p>
            A OdontoGroup visa garantir que os usuários de nossos sites e nossos
            beneficiários estejam cientes de que seus dados estão sendo
            processados e que compreendam:
          </p>
          <p>
            <ul className="P-square">
              <li>
                <p>Como os dados estão sendo usados; e</p>
              </li>
              <li>
                <p>Como exercer seus direitos.</p>
              </li>
            </ul>
          </p>
          <p>Esta política de proteção de dados garante que a OdontoGroup:</p>
          <p>
            <ul className="P-square">
              <li>
                <p>
                  Cumpre as leis de proteção de dados e segue as boas práticas;
                </p>
              </li>
              <li>
                <p>
                  Protege os direitos de clientes, funcionários, afiliados e
                  parceiros;
                </p>
              </li>
              <li>
                <p>
                  É aberto sobre como armazena e processa dados de indivíduos; e
                </p>
              </li>
              <li>
                <p>Protege-se do risco de violação de dados.</p>
              </li>
            </ul>
          </p>
          <p>
            <h2>
              <strong>COMO AS INFORMAÇÕES SÃO COLETADAS?</strong>
            </h2>
          </p>
          <p>
            A quantidade e o tipo de informações coletadas variam conforme o uso
            que você faz dos nossos serviços. Coletaremos diferentes dados para
            diferentes finalidades, desde a visita a um de nossos sites até a
            aquisição e utilização de um de nossos Planos de Odontologia.
          </p>
          <p>
            Não coletamos nenhuma informação de identificação pessoal sobre
            você, a menos que você a forneça voluntariamente. Você nos fornece
            certas Informações de Identificação Pessoal quando: inscreve-se em
            nossos serviços e registra seu endereço de e-mail conosco;
            inscreve-se para ofertas especiais de terceiros selecionados; envia
            mensagens de e-mail, envia formulários ou transmite outras
            informações por telefone ou mensagens eletrônicas; ou envie seu
            cartão de crédito ou outras informações de pagamento ao solicitar e
            comprar produtos e serviços em nosso site. Também podemos coletar
            informações suas em outros pontos do nosso site que afirmam que tais
            informações estão sendo coletadas.
          </p>
          <p>
            Além disso, também podemos coletar, ou nosso servidor de anúncios
            e/ou servidor de conteúdo de terceiros pode coletar, certas
            informações não pessoalmente identificáveis. Em última análise,
            essas informações são armazenadas na forma de categorias de loja e,
            em alguns casos, URLs específicos. Usamos seu endereço IP para
            diagnosticar problemas com nossos servidores e software, para
            administrar nosso site e para coletar informações demográficas.
            Nossos servidores de anúncios de terceiros também nos fornecerão
            relatórios resumidos, mas não individuais, que nos dirão quantos
            anúncios foram apresentados e clicados em nosso site.
          </p>
          <p>
            <h2>
              <strong>COMO AS INFORMAÇÕES SERÃO UTILIZADAS?</strong>
            </h2>
          </p>
          <p>
            Usaremos principalmente suas informações de identificação pessoal
            para fornecer nossos serviços a você, conforme exigido por nossos
            contratos com você. Também usaremos as Informações de Identificação
            Pessoal para aprimorar a operação de nosso site, atender pedidos,
            melhorar nossos esforços promocionais e de marketing, analisar
            estatisticamente o uso do site, melhorar nossas ofertas de produtos
            e serviços e personalizar o conteúdo, layout e serviços de nosso
            site. Podemos usar informações de identificação pessoal para
            fornecer informações a você e contatá-lo a respeito de notificações
            administrativas. Também podemos usar as Informações de Identificação
            Pessoal para resolver disputas, solucionar problemas e fazer cumprir
            nossos acordos com você, incluindo os Termos de Uso do Site, os
            Termos e Condições de Vendas e esta Política Privada.
          </p>
          <p>
            A seguir, alguns exemplos de quais e como as informações são
            utilizadas, de acordo com o tipo de necessidade do usuário.
          </p>
          <p style={{ marginLeft: '25px' }}>
            <strong>1. Visitantes dos Nossos Sites</strong>
          </p>
          <table id="tabelafim">
            <thead>
              <tr>
                <td>Finalidade</td>
                <td>Dados Coletados</td>
                <td>Base Legal</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Acesso aos sites</td>
                <td>IP, data e hora de acesso, cookies.</td>
                <td>
                  <p>Obrigação Legal</p>
                  <p>Legítimo interesse</p>
                </td>
              </tr>
              <tr>
                <td>Agendamento de consultas odontológicas e exames e CRM</td>
                <td>
                  E-mail, número da carteirinha do plano, código do
                  beneficiário, data de nascimento e CPF
                </td>
                <td>Consentimento</td>
              </tr>
              <tr>
                <td>Emissão de 2º via de boleto</td>
                <td>CPF</td>
                <td>Exercício regular de direitos em contrato </td>
              </tr>
              <tr>
                <td>Solicitação de Plano Odontológico</td>
                <td>
                  Nome completo, endereço, CPF, RG, dados de contato, nome da
                  mãe
                </td>
                <td>Consentimento</td>
              </tr>
              <tr>
                <td>Atendimento (dúvidas, reclamações) </td>
                <td>Nome, CPF, e-mail e telefone</td>
                <td>
                  Consentimento Obrigação legal ou Legítimo interesse
                  (dependendo do atendimento)
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p style={{ marginLeft: '25px' }}>
            <strong>2. Beneficiários dos Planos Odontológicos</strong>
          </p>
          <p>
            <strong>Adesão ao Plano Odontológico:</strong> Seus dados serão
            utilizados caso contrate um de nossos planos. Serão necessários
            dados como: nome, dada de nascimento, estado civil, endereço,
            telefone, nome da mãe e CPF.
          </p>
          <p>
            <strong>Inclusão de beneficiário no Plano de Saúde:</strong> Para a
            inclusão de beneficiários no plano, bem como outras solicitações
            correlatas (como reativação de plano), é necessário que a
            OdontoGroup tenha acesso às informações como: RG, CPF, GFIP,
            certidão de nascimento, data de nascimento, certidão de casamento,
            código do usuário, cidade, mensalidade, custos, dados de pagamento,
            entre outros.
          </p>
          <p>
            <strong>Permitir acesso e uso das nossas plataformas:</strong>{' '}
            Desenvolvemos algumas plataformas e aplicativos que permitem o uso
            de alguns de nossos serviços por meio de produtos digitais, tais
            como o aplicativo App do Beneficiário. Utilizaremos os seus dados
            pessoais e de saúde como nome, CPF, e-mail, telefone, número da
            carteira do plano, para que você possa acessar a esses Serviços e
            funcionalidades.
          </p>
          <p>
            <strong>Participação em programas de benefícios:</strong> Oferecemos
            alguns programas de benefícios aos nossos beneficiários. Caso você
            aceite participar desses programas, podemos coletar dados como nome,
            CPF, CNS, RG, data de nascimento, e-mail, telefone e receituário e
            compartilhar com nossos parceiros de benefícios.
          </p>
          <p>
            <strong>Liberação de guias, procedimentos e reembolso:</strong> Para
            que possamos prestar devidamente nossos serviços, é necessário
            coletar determinadas informações sobre seus procedimentos para que
            possamos liberar sua realização por meio do seu Plano Odontológico e
            fazer seu reembolso. Para que essas liberações possam ocorrer,
            podemos tratar os seguintes dados: nome completo, número da
            carteirinha, idade, gênero, endereço, telefone, e-mail, produto ou
            procedimento contratado e informações bancárias do beneficiário para
            reembolso.
          </p>
          <p>
            <strong>Auditoria de procedimentos:</strong> Para que possamos dar
            continuidade ao procedimento solicitado pelo seu dentista, por vezes
            é necessário verificar a pertinência da solicitação. Essa análise é
            feita por profissionais da área da saúde e pode envolver, por
            exemplo, as informações do pedido odontológico, laudos de exames e
            fotos dos procedimentos para confirmar a pertinência do tratamento
            solicitado.
          </p>
          <p>
            <strong>
              Análise atuária, estudos estatísticos e financeiros:
            </strong>{' '}
            Coletamos informações para realizar análise e projeção de custo dos
            planos odontológicos, o que auxilia na tomada de decisão
            estratégica. Essa análise pode envolver todos os dados sobre os
            procedimentos autorizados pela OdontoGroup, bem como informações de
            saúde que constem nos prontuários, como anamnese, exames, evolução,
            tratamentos realizados etc.
          </p>
          <p>
            <strong>Oferecimento de novos produtos e serviços:</strong>{' '}
            Coletamos informações para enviar comunicações a respeito de novos
            serviços, eventos, oportunidades e iniciativas promovidas pela
            OdontoGroup que talvez você tenha interesse.
          </p>
          <p>
            <strong>Responder dúvidas, solicitações e fornecer suporte:</strong>{' '}
            Quando você entra em contato conosco, inclusive por meio de redes
            sociais (Facebook, Instagram, YouTube, LinkedIn e Reclame Aqui),
            trataremos seus dados pessoais para processar as suas solicitações,
            fornecer as devidas informações e suporte para uso dos nossos
            serviços, assim como esclarecer dúvidas. Para isso, podemos coletar
            os seguintes dados: nome completo, motivo do contato, números de
            contato, informações sobre seu plano de saúde, bem como outras
            informações fornecidas por você durante suas interações.
          </p>
          <p style={{ marginLeft: '25px' }}>
            <strong>3. Consultórios e Clínicas</strong>
          </p>
          <p>
            <strong>Realizar exames e procedimentos:</strong> Coletamos dados
            compatíveis com a finalidade de cada tratamento que você fizer em um
            dos pontos de atendimento odontológico. Esses dados podem ser
            utilizados para a realização e entrega de resultados de exames, bem
            como para enviar comunicações necessárias sobre andamento e
            agendamento de exames e tratamentos.
          </p>
          <p>
            <strong>Programas especiais de cuidados preventivos:</strong> Nessas
            situações podemos coletar e tratar dados necessários para prover os
            serviços de cuidados preventivos à sua saúde bucal.
          </p>
          <p>
            <strong>Oferecimento de novos produtos e serviços:</strong>{' '}
            Coletamos informações para enviar comunicações a respeito de novos
            serviços, eventos, oportunidades e iniciativas promovidas pela
            OdontoGroup que talvez você tenha interesse.
          </p>
          <p>
            <strong>Responder dúvidas, solicitações e fornecer suporte:</strong>{' '}
            Quando você entra em contato conosco, inclusive por meio de redes
            sociais (Facebook, Instagram, YouTube, LinkedIn e Reclame Aqui),
            trataremos seus dados pessoais para processar as suas solicitações,
            fornecer as devidas informações e suporte para uso dos nossos
            serviços, assim como esclarecer dúvidas. Para isso, podemos coletar
            os seguintes dados: nome completo, motivo do contato, números de
            contato, informações sobre seu plano de saúde, bem como outras
            informações fornecidas por você durante suas interações.
          </p>
          <p>
            <strong>Pesquisas de satisfação e de acolhimento: </strong> Para que
            possamos melhorar nossos serviços, ao final do seu atendimento
            podemos solicitar a realização de pesquisas de satisfação. Nesses
            casos, apenas se você aceitar participar de tais pesquisas, podemos
            coletar seu nome completo, idade, telefone, tipo de plano, tempo de
            utilização do plano, código do usuário, local de atendimento,
            procedimento realizado, tempo de atendimento e entre outras
            informações necessárias ao bom desenvolvimento da pesquisa.
          </p>
          <p>
            <h2>
              <strong>COM QUEM AS INFORMAÇÕES SÃO COMPARTILHADAS?</strong>
            </h2>
          </p>
          <p>
            <strong>
              Divulgação de informações para provedores de serviços e
              fornecedores terceirizados.
            </strong>{' '}
            A OdontoGroup pode divulgar suas Informações de Identificação
            Pessoal a prestadores de serviços e fornecedores terceirizados para
            efetuar ou realizar qualquer transação que você tenha solicitado
            conosco ou conforme necessário para concluir nossas obrigações
            contratuais com você. Isso inclui provedores de serviços de e-mail,
            provedores de serviços e aplicativos de marketing direto, incluindo
            pesquisa e referência e serviços de aprimoramento, supressão e
            validação de dados, além de rede credenciada de saúde, agência
            reguladora, empresas prestadoras de serviços de contabilidade,
            auditoria e assessoria especializada. Quando o compartilhamento dos
            seus Dados Pessoais for necessário adotaremos, sempre que for
            possível, mecanismos de anonimização ou pseudonimização desses dados
            serão aplicados.
          </p>
          <p>
            A OdontoGroup proíbe contratualmente a venda ou transferência de
            Informações de Identificação Pessoal por tais provedores de serviços
            e fornecedores. A OdontoGroup pode compartilhar Informações de
            Identificação Pessoal com empresas afiliadas que são diretas ou
            indiretamente controladas por, ou sob o controle comum da
            OdontoGroup.
          </p>
          <p>
            Oferecemos alguns de nossos serviços por meio de acordos contratuais
            com afiliados, prestadores de serviços, parceiros e outros
            terceiros. Nós e nossos parceiros de serviço usamos suas informações
            de identificação pessoal para operar nossos sites e fornecer seus
            serviços. Por exemplo, devemos liberar as informações do seu cartão
            de crédito para o banco emissor do cartão para confirmar o pagamento
            dos produtos e serviços adquiridos neste site; liberar suas
            informações de endereço para o serviço de entrega para entregar os
            produtos que você solicitou; e fornecer informações sobre pedidos a
            terceiros que nos ajudem a fornecer atendimento ao beneficiário.
          </p>
          <p>
            Ocasionalmente, podemos ser solicitados pelas autoridades policiais
            ou judiciais a fornecer Informações de Identificação Pessoal às
            autoridades governamentais apropriadas. Divulgaremos informações de
            identificação pessoal após o recebimento de uma ordem judicial,
            intimação ou para cooperar com uma investigação policial. Cooperamos
            totalmente com as agências de aplicação da lei na identificação
            daqueles que usam nossos serviços para atividades ilegais. Nós nos
            reservamos o direito de relatar às agências de aplicação da lei
            quaisquer atividades que, de boa-fé, acreditamos ser ilegais.
          </p>
          <p>
            Também podemos fornecer informações de identificação não pessoal
            sobre as vendas de nossos clientes, padrões de tráfego e informações
            de sites relacionadas a anunciantes terceiros, mas essas
            estatísticas não incluem nenhuma informação de identificação
            pessoal.
          </p>
          <p>
            Poderemos compartilhar os Dados Pessoais nas situações
            exemplificadas a seguir, mas não limitadas a elas:
          </p>
          <ul className="P-square">
            <li>
              <p>
                <strong>Fornecedores:</strong> Temos determinadas categorias de
                fornecedores que precisamos contratar para oferecer os nossos
                serviços, e alguns deles podem tratar em nosso nome os Dados
                Pessoais que recebemos. Por exemplo, contratamos os serviços de
                call center para prestar o atendimento telefônico, assim como
                contratamos serviços de diversos outros terceiros como de
                auditoria, advocatícios e serviços de hospedagem em nuvem, por
                exemplo.
              </p>
            </li>
            <li>
              <p>
                <strong>Consultórios e Clínicas:</strong> Quando você é um
                beneficiário dos nossos Planos Odontológicos, para que possamos
                prestar devidamente nossos serviços, é necessário compartilhar
                determinadas informações com os consultórios e clínicas sua
                escolha sobre seus procedimentos para que possamos liberar a
                realização por meio do seu Plano Odontológico.
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Empresa ou instituição onde você trabalha ou esteja associado:
                </strong>{' '}
                Se a empresa ou instituição onde você trabalha ou está associado
                contratou nossos serviços de Plano Odontológico, é possível que
                determinadas informações sejam compartilhadas com sua empresa /
                instituição para que você possa exercer seus direitos
                relacionados ao nosso contrato.
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Para resguardar e proteger direitos da OdontoGroup:
                </strong>{' '}
                A OdontoGroup se reserva no direito de acessar, preservar e
                fornecer quaisquer dados e informações sobre você, caso sejam
                necessários para cumprir uma obrigação legal ou uma ordem
                judicial; para fazer cumprir ou aplicar nossos contratos; ou
                proteger os direitos, propriedade ou segurança da OdontoGroup,
                bem como de nossos funcionários e/ou outros usuários. Por
                exemplo, podemos compartilhar determinadas informações com o
                Ministério Público e/ou com a polícia quando solicitado por
                esses órgãos, apenas na medida do que for autorizado legalmente.
              </p>
            </li>
            <li>
              <p>
                <strong>Para cumprir obrigações legais e regulatórias:</strong>{' '}
                Transferimos dados diversos para a Agência Nacional de Saúde
                Suplementar - ANS, através de seus aplicativos e relatórios
                padronizados, no estrito cumprimento do dever legal ao qual o
                setor de saúde suplementar está sujeito.
              </p>
            </li>
          </ul>
          <p>
            <h2>
              <strong>E QUANTO À SEGURANÇA DA INFORMAÇÃO?</strong>
            </h2>
          </p>
          <p>
            Implementamos padrões geralmente aceitos de tecnologia e segurança
            operacional para proteger as informações de identificação pessoal
            contra perda, uso indevido, alteração ou destruição. Apenas pessoal
            autorizado têm acesso às suas informações pessoais, e esses
            funcionários e fornecedores são obrigados a tratar essas informações
            como confidenciais. Suas informações de identificação pessoal são
            protegidas de várias maneiras e residem em um servidor seguro ao
            qual apenas pessoas selecionadas da OdontoGroup têm acesso, por meio
            de senha, sejam eles Colaboradores Internos, Beneficiários, Gestores
            de Contratos Empresariais ou Coletivos por Adesão, Dentistas ou
            Prestadores da Rede Credenciada, Corretores e Vendedores.
          </p>
          <p>
            Para atendê-lo com mais eficiência, as transações de cartão de
            crédito e o atendimento de pedidos são administrados por bancos
            terceirizados, agentes de processamento e instituições de
            distribuição. Eles recebem as informações necessárias para verificar
            e autorizar seu cartão de crédito ou outras informações de pagamento
            e para processar e entregar seu pedido.
          </p>
          <p>
            Com a finalidade de garantir a segurança de seus Dados Pessoais em
            nossos sistemas, a OdontoGroup adota as melhores práticas de
            segurança da informação disponíveis, incluindo:
          </p>
          <ul className="P-square">
            <li>
              <p>
                A utilização de criptografia para transmissão e armazenamento de
                determinados dados pessoais;
              </p>
            </li>
            <li>
              <p>
                Adoção de controles de acesso rigorosos aos dados pessoais
                armazenados nos sistemas;
              </p>
            </li>
            <li>
              <p>
                Utilização de mecanismos técnicos contra acesso não autorizado
                sistemas por meio de firewalls, anti-malware, e outros; e
              </p>
            </li>
            <li>
              <p>
                Implementação procedimentos preventivos contra incidentes de
                segurança da informação.
              </p>
            </li>
          </ul>
          <p>
            Infelizmente, nenhuma transmissão de dados pela Internet ou qualquer
            rede sem fio pode ser garantida como 100% segura. Como resultado,
            enquanto nos esforçamos para proteger suas informações de
            identificação pessoal, você reconhece que: existem limitações de
            segurança e privacidade da Internet que estão além do nosso
            controle; a segurança, integridade e privacidade de toda e qualquer
            informação e dados trocados entre você e nós através deste site não
            podem ser garantidos; e quaisquer informações e dados podem ser
            visualizados ou adulterados em trânsito por terceiros.
          </p>
          <p>
            Caso você identifique ou tome conhecimento de algo que comprometa a
            segurança da informação da OdontoGroup, por favor entre em contato
            conosco.
          </p>
          <p>
            <h2>
              <strong>O QUE SÃO COOKIES E COMO SÃO USADOS?</strong>
            </h2>
          </p>
          <p>
            Quando você usa nosso site, armazenamos cookies em seu computador
            para facilitar e personalizar sua experiencia de uso em nosso site.
            Um cookie é um pequeno arquivo de texto de dados, que um site da Web
            armazena no disco rígido do seu computador ou dispositivo (se seu
            navegador da Web permitir) que pode ser recuperado posteriormente
            para identificá-lo para nós. Nossos cookies armazenam números de
            identificação de usuário atribuídos aleatoriamente, o país onde você
            está localizado e seu primeiro nome para recebê-lo de volta ao nosso
            site. Os cookies facilitam a sua utilização do site, facilitam o
            funcionamento do site e ajudam-nos a manter um site seguro. Você
            está sempre livre para recusar nossos cookies se seu navegador
            permitir, mas algumas partes de nosso site podem não funcionar
            corretamente nesse caso.
          </p>
          <p>
            Os cookies geralmente têm uma data de expiração. Alguns cookies são
            excluídos automaticamente quando você fecha o navegador (os chamados
            cookies de sessão), enquanto outros podem ser armazenados por mais
            tempo no dispositivo até serem excluídos manualmente (os chamados
            cookies persistentes).
          </p>
          <p>
            Podemos usar uma empresa externa de veiculação de anúncios para
            exibir anúncios em banner em nosso site. Como parte do serviço, eles
            colocarão um cookie separado em seu computador. Não forneceremos a
            nenhum servidor de anúncios de terceiros nenhuma das suas
            informações de identificação pessoal ou informações sobre suas
            compras. Nós e nosso servidor de anúncios terceirizado coletaremos e
            usaremos informações de identificação não pessoal sobre você, como
            seu endereço IP, tipo de navegador, o servidor em que seu computador
            está conectado, o código de área e código postal associados ao seu
            servidor e se você respondeu a um determinado anúncio. Outros
            anunciantes também podem colocar banners em nosso site da mesma
            maneira que acima, mas não divulgaremos nenhuma informação de
            identificação pessoal a eles.
          </p>
          <p>
            <h2>
              <strong>QUE TIPOS DE COOKIES SÃO UTILIZADOS?</strong>
            </h2>
          </p>
          Os sites da OdontoGroup utilizam os seguintes cookies:
          <ul className="P-square">
            <li>
              <p>
                <strong>Cookies estritamente necessários</strong>
              </p>
            </li>
            <ul className="P-circle">
              <li>
                <p>
                  <strong>Cookies necessários:</strong> garantem que nossos
                  sites funcionem corretamente. Não é possível recusar estes
                  cookies se você quiser visitar nossos sites.
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies de funcionalidade:</strong> permitem a
                  implementação de funcionalidades otimizadas, como a
                  visualização de vídeos.
                </p>
              </li>
            </ul>
            <li>
              <p>
                <strong>Cookies analíticos</strong>
              </p>
            </li>
            <ul className="P-circle">
              <li>
                <p>
                  <strong>Cookies de análise:</strong> permitem a coleta de
                  informações estatísticas sobre como nossos sites são
                  utilizados, para que possamos melhorar nossos sites e para que
                  possamos entender o perfil de nossos usuários.
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies de marketing:</strong> permitem a
                  disponibilização de ofertas de produtos e serviços, de acordo
                  com seus interesses.
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies de mídias sociais:</strong> para habilitar
                  plugins de determinadas redes sociais, quando o usuário
                  escolhe acessar nosso site por meio dessas redes.
                </p>
              </li>
            </ul>
          </ul>
          <p>
            Assim, caso você deseje recusar a instalação desses cookies no seu
            dispositivo, acesse a qualquer momento nosso <u>Aviso de Cookies</u>{' '}
            para configurar os cookies utilizados.
          </p>
          <p>
            Esclarecemos que a OdontoGroup não se responsabiliza pelo uso de
            cookies por terceiros. Fique atento, pois os cookies colocados por
            terceiros podem eventualmente continuar a monitorar as suas
            atividades online mesmo depois de ter saído dos nossos sites, sendo
            recomendável que limpar seu histórico de navegação regularmente
            pelas configurações do seu navegador.
          </p>
          <p>
            <h2>
              <strong>
                E QUANTO ÀS POLÍTICAS DE PRIVACIDADE DE SITES DE TERCEIROS?
              </strong>
            </h2>
          </p>
          <p>
            Este documento trata apenas do uso e divulgação de informações que
            coletamos de você. Outros sites acessíveis através do nosso site têm
            suas próprias políticas de privacidade e coleta de dados, uso e
            prática de divulgação. Não somos responsáveis pelas políticas ou
            práticas de terceiros.
          </p>
          <p>
            <h2>
              <strong>SOBRE A LEI GERAL DE PROTEÇÃO DE DADOS (LGPD)</strong>
            </h2>
          </p>
          <p>
            A OdontoGroup precisa coletar e usar certas informações sobre os
            indivíduos para aprimorar a experiência e compreender algumas
            dimensões qualitativas e quantitativas sobre os indivíduos,
            otimizando assim toda a empresa como um ciclo de feedback ativo.
            Esses indivíduos podem incluir clientes, afiliados, contatos
            comerciais, funcionários e outras pessoas com as quais a organização
            tem um relacionamento ou pode precisar entrar em contato.
          </p>
          <p>
            Temos o compromisso de proteger seus dados, para os quais os
            protocolos foram exercidos conforme descrito nesta Política de
            Privacidade. A LGPD estipula protocolos e práticas rígidas em
            relação à forma como a coleta de dados e o consentimento dos
            indivíduos são gerenciados. Ao aderir a essas regulamentações, a
            demonstramos ainda mais nosso compromisso em proteger a segurança e
            privacidade dos dados de nossos clientes, capacitando nossos
            clientes com o controle de como suas informações e consentimento são
            coletados, registrados e usados.
          </p>
          <p>
            Sempre que os nossos serviços forem prestados a pessoas menores de
            12 (doze) anos de idade buscaremos a autorização de seus
            representantes legais, nos termos das legislações aplicáveis.
          </p>
          <p>
            <h2>
              <strong>ATENDENDO AOS PRINCÍPIOS DA LGPD</strong>
            </h2>
          </p>
          <p>
            A Lei Geral de Proteção de Dados - LGPD descreve como as
            organizações devem coletar, manipular e armazenar informações
            pessoais. Essas regras se aplicam independentemente de os dados
            serem armazenados eletronicamente, em papel ou em outros materiais.
            Para cumprir a lei, as informações pessoais devem ser coletadas e
            usadas de forma justa, armazenadas com segurança e não divulgadas de
            forma ilegal.
          </p>
          <p>
            A LGPD é sustentada por dez princípios importantes. Esses princípios
            afirmam que os dados pessoais devem:
          </p>
          <ul className="P-count">
            <li>
              <p>
                Ter finalidade específica e informada explicitamente ao titular;
              </p>
            </li>
            <li>
              <p>Adequação a finalidade previamente acordada e divulgada;</p>
            </li>
            <li>
              <p>
                Necessidade do tratamento, limitado ao uso de dados essenciais
                para alcançar a finalidade essencial;
              </p>
            </li>
            <li>
              <p>
                Acesso livre, fácil e gratuito das pessoas à forma como seus
                dados serão tratados;
              </p>
            </li>
            <li>
              <p>
                Qualidade dos dados, deixando-os exatos e atualizados, segundo a
                real necessidade no tratamento;
              </p>
            </li>
            <li>
              <p>
                Transparência, ao titular, com informações claras e acessíveis
                sobre o tratamento e seus responsáveis;
              </p>
            </li>
            <li>
              <p>
                Segurança para coibir situações acidentais ou ilícitas como
                invasão, destruição, perda, difusão;
              </p>
            </li>
            <li>
              <p>Proteção contra danos ao titular e a demais envolvidos;</p>
            </li>
            <li>
              <p>
                Não discriminação, ou seja, não permitir atos ilícitos ou
                abusivos; e
              </p>
            </li>
            <li>
              <p>
                Responsabilização do agente, obrigado a demonstrar a eficácia
                das medidas adotadas.
              </p>
            </li>
          </ul>
          <p>
            <h2>
              <strong>QUAIS OS DIREITOS DO TITULAR DOS DADOS?</strong>
            </h2>
          </p>
          <p>
            A OdontoGroup estabeleceu políticas para proteger os direitos dos
            usuários. Permitimos que nossos clientes optem por não receber
            notificações e sempre responderemos a solicitações de acesso a
            dados. Aqui, demonstramos quais são os direitos e quais as formas de
            exercê-los junto à OdontoGroup.
          </p>
          <p>
            <strong>Direito de confirmação do tratamento:</strong> O titular tem
            direito à confirmação da existência de tratamento, ou seja, direito
            de saber se seus dados pessoais são ou não objeto de tratamento pela
            OdontoGroup.
          </p>
          <p>
            <strong>Direito de acesso:</strong> Você tem o direito de obter
            informações sobre como seus Dados Pessoais são utilizados, com quem
            eles são compartilhados, e de requisitar uma cópia dos mesmos,
            respeitando eventuais direitos de propriedade intelectual.
          </p>
          <p>
            <strong>
              Correção de dados incompletos, inexatos ou desatualizados:
            </strong>{' '}
            Você tem o direito de solicitar a correção e/ou retificação dos seus
            Dados Pessoais, caso identifique que algum deles esteja incorreto.
          </p>
          <p>
            <strong>Anonimização, bloqueio ou eliminação de dados:</strong> Este
            direito permite que você solicite que seus dados pessoais tidos como
            desnecessários, excessivos ou tratados em desconformidade com o
            disposto na Lei sejam anonimizados, bloqueados ou eliminados. No
            entanto, se por qualquer outra razão, for necessária a manutenção
            destes dados, como eventual obrigação legal de retenção de dados ou
            necessidade de preservação destes para resguardo de direitos, talvez
            eles não possam ser anonimizados, bloqueados ou eliminados.
          </p>
          <p>
            <strong>
              Portabilidade dos dados a outro fornecedor de serviço ou produto:
            </strong>{' '}
            Você tem o direito de solicitar que seja entregue a você, ou a
            terceiros que você escolher, seus Dados Pessoais em formato
            estruturado e interoperável, respeitando eventuais direitos de
            propriedade intelectual.
          </p>
          <p>
            <strong>Uso compartilhado de dados:</strong> Você tem o direito de
            saber com quais entidades públicas e privadas a OdontoGroup
            compartilha os dados.
          </p>
          <p>
            <strong>Possibilidade de não fornecer consentimento:</strong> Você
            tem o direito não consentir com o tratamento de seus dados. Caso
            isso ocorra e nos pontos aonde é possível não consentir, o não
            consentimento impossibilitará que você usufrua de partes da sua
            aquisição.
          </p>
          <p>
            <strong>Revogação do consentimento:</strong> Você tem o direito de
            retirar o seu consentimento, no entanto, isso não afetará a
            legalidade de qualquer processamento realizado antes de você
            retirá-lo. Se você retirar o seu consentimento, talvez determinados
            serviços referentes ao seu plano odontológico sejam interrompidos.
          </p>
          <p>
            <strong>
              Eliminação dos dados pessoais tratados com o consentimento do
              titular:
            </strong>{' '}
            Você tem o direito de solicitar, mediante requerimento expresso, a
            eliminação dos dados pessoais tratados com o seu consentimento,
            exceto nas hipóteses previstas no artigo 16 da LGPD (cumprimento de
            obrigação legal ou regulatória pela OdontoGroup).
          </p>
          <p>
            <strong>Direito de petição:</strong> Você tem o direito de
            peticionar em relação aos seus dados contra o controlador perante a
            perante a autoridade nacional – ANPD.
          </p>
          <p>
            <strong>Direito de oposição:</strong> Você também tem o direito de
            contestar onde e em que contexto a OdontoGroup trata seus Dados
            Pessoais para diferentes finalidades. Em determinadas situações,
            poderemos demonstrar que temos motivos legítimos para tratar seus
            Dados.
          </p>
          <p>
            <h2>
              <strong>
                QUAIS AS BASES LEGAIS PARA O TRATAMENTO DOS DADOS?
              </strong>
            </h2>
          </p>
          <p>
            A OdontoGroup obtém consentimento automático dos usuários para os
            fins para os quais o contrato de plano de saúde é celebrado; e
            através desta Política de Privacidade ao contratar nossos Planos
            Odontológicos ou utilizar nossos sites. No entanto, por tratar-se de
            Plano Odontológico, contratado pelo titular dos dados ou por seu
            representante, as bases legais também podem enquadrar-se,
            adicionalmente a:
          </p>
          <ul className="P-count">
            <li>
              <p>Cumprimento de obrigação legal ou regulatória;</p>
            </li>
            <li>
              <p>
                Execução e contrato celebrado com o titular dos dados pessoais;
              </p>
            </li>
            <li>
              <p>Exercício regular de direitos;</p>
            </li>
            <li>
              <p>Legítimo interesse da OdontoGroup.</p>
            </li>
          </ul>
          <p>
            Os usuários podem retirar o consentimento, para parte dos dados,
            considerando que o tratamento de alguns deles não dependem de
            consentimento do titular, fazendo uma solicitação formal através dos
            canais adequados.
          </p>
          <p>
            <h2>
              <strong>E POR QUANTO TEMPO OS DADOS SERÃO ARMAZENADOS?</strong>
            </h2>
          </p>
          <p>
            Nós manteremos seus Dados Pessoais somente pelo tempo que for
            necessário para cumprir com as finalidades estabelecidas a fim de
            fornecermos nossos serviços, e, inclusive, para fins de cumprimento
            de quaisquer obrigações legais, contratuais, de prestação de contas
            ou requisição de autoridades competentes.
          </p>
          <p>
            Para determinar o período de retenção adequado para os Dados
            Pessoais, consideramos a quantidade, a natureza e a sensibilidade
            dos Dados Pessoais, o risco potencial de danos decorrentes do uso
            não autorizado ou da divulgação de seus Dados Pessoais, a finalidade
            de tratamento dos seus Dados Pessoais, se podemos alcançar tais
            propósitos através de outros meios, e os requisitos legais
            aplicáveis.
          </p>
          <p>
            Após encerradas todas as finalidades que justificavam o tratamento
            dos Dados nos nossos sistemas, eles serão eliminados e/ou
            anonimizados, observando os meios técnicos disponíveis, salvo se for
            necessária à sua manutenção para cumprimento de obrigações legais,
            contratuais, de prestação de contas ou requisição de autoridades
            competentes.
          </p>
          <p>
            <h2>
              <strong>NOTIFICAÇÕES DE VIOLAÇÃO DE DADOS</strong>
            </h2>
          </p>
          <p>
            Fazemos o nosso melhor para proteger seus dados, embora o inesperado
            possa acontecer. Temos o compromisso de sempre ser totalmente
            transparentes e notificar a autoridade supervisora e todas as partes
            afetadas de acordo com os requisitos da LGPD.
          </p>
          <p>
            <h2>
              <strong>
                QUEM SOMOS NÓS? O CONTROLADOR DE DADOS E OFICIAL DE PROTEÇÃO DE
                DADOS
              </strong>
            </h2>
          </p>
          <p>
            A OdontoGroup possui salvaguardas para manter suas informações
            pessoais precisas, completas e atualizadas para os fins para os
            quais são usadas. Você sempre tem o direito de acessar e corrigir as
            informações pessoais fornecidas e pode nos ajudar a garantir que
            suas informações de contato e preferências sejam precisas, completas
            e atualizadas verificando o portal do beneficiário ou acessando seus
            dados através do aplicativo para beneficiários OdontoGroup.
          </p>
          <p>
            O <strong>Controlador de Dados</strong> para os dados processados
            sob esta Política de Privacidade é a OdontoGroup Sistema de Saúde
            Ltda.
          </p>
          <p>
            Você pode obter suas informações através do portal do beneficiário
            ou através do aplicativo para beneficiários da OdontoGroup. Caso
            prefira, também poderá solicitar suas informações pessoais ou
            esclarecer dúvidas sobre a proteção de dados entrando em contato com
            a nossa equipe, através do{' '}
            <strong>Encarregado de Proteção de Dados (DPO):</strong>
          </p>
          <p>Nome: Ana Maria Borzan Cruz</p>
          <p>
            E-mail: dpo.atendimento@odontogroup.com.br
            <br />
            <br />
            Talvez seja necessário que você nos forneça informações específicas
            suas para nos ajudar a confirmar sua identidade e garantir o
            exercício dos seus direitos como Titular. Esta é uma medida de
            segurança para garantir que os Dados Pessoais não sejam divulgados a
            qualquer pessoa que não tenha direito de recebê-los.
          </p>
          <p>
            <strong> Revisada em 31/07/2024.</strong>
          </p>
          <a
            href="https://www.odontogroup.com.br/wp-content/uploads/2024/08/politica_de_privacidade.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Baixar Política de Privacidade
          </a>
          <p></p>
        </div>
      </div>
    </div>
  );
}
