import type { ReactElement } from 'react';

import { PageHelmet } from '../../components/PageHelmet';
import VideoBanner from '../../components/VideoBanner';
import ActionsSection1 from './Actions1';
import ActionsSection2 from './Actions2';
import AppsSection from './Apps';
import HealthSection from './Health';
import SpacesSection from './Spaces';
import YourPlanSection from './YourPlan';

const HomePage = (): ReactElement => (
  <>
    <PageHelmet title="OdontoGroup | Saúde começa aqui" />

    <VideoBanner page="home" />
    <YourPlanSection />
    <ActionsSection1 />
    <SpacesSection />
    <AppsSection />
    <ActionsSection2 />
    <HealthSection />
  </>
);
export default HomePage;
