export const socialMedia = [
  {
    name: 'Facebook',
    src: '/assets/logos/social-media/facebook.svg',
    link: process.env.REACT_APP_FACEBOOK ?? '/',
  },
  {
    name: 'Instagram',
    src: '/assets/logos/social-media/instagram.svg',
    link: process.env.REACT_APP_INSTAGRAM ?? '/',
  },
  // {
  //   name: 'WhatsApp',
  //   src: '/assets/logos/social-media/whatsapp.svg',
  //   link: `https://wa.me/${process.env.REACT_APP_CONTACT_CAC}?text=Quero contratar um plano odontológico!`,
  // },
  {
    name: 'LinkedIn',
    src: '/assets/logos/social-media/linkedin.svg',
    link: process.env.REACT_APP_LINKEDIN ?? '/',
  },
];
