import { useEffect, type ReactElement } from 'react';
import { Link } from 'react-router-dom';
// import { HashLink } from 'react-router-hash-link';

import { useMobileMenuStore } from '../../stores/useMobileMenuStore';
import { useScreenStore } from '../../stores/useScreenStore';
import { Button } from '../Button';
import { CartIcon } from '../icons/CartIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { MobileMenu } from './MobileMenu';
import { Navbar } from './Navbar';

export function Header(): ReactElement {
  const { xl } = useScreenStore(state => state.screen);
  const toggleMenu = useMobileMenuStore(state => state.toggleMenu);

  useEffect(() => {
    const embedScript = document.createElement('script');
    embedScript.src =
      'https://app.acessibilizar.com.br/embed-scripts/7e4a1c6d-2511-425c-8e59-6883b3fb3714';
    embedScript.type = 'text/javascript';
    embedScript.async = true;
    embedScript.setAttribute(
      'embed-id',
      '7e4a1c6d-2511-425c-8e59-6883b3fb3714',
    );

    document.head.appendChild(embedScript);

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      document.head.removeChild(embedScript);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://rum.cronitor.io/script.js';
    script.async = true;

    document.head.appendChild(script);

    script.onload = () => {
      window.cronitor =
        window.cronitor ||
        function (...args: any[]) {
          (window.cronitor.q = window.cronitor.q || []).push(args);
        };

      window.cronitor('config', {
        clientKey: '65f666269c62f0b0fb8ef819b7355d70',
      });
    };

    return () => {
      // Cleanup script if component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <header className="z-40 fixed top-0 w-full h-[82px] flex items-center bg-[#F9F9F9] xl:rounded-b-3xl xl:shadow-card border-b border-b-neutral-lightest">
        <div className="xl:max-w-[1621px] 3xl:px-0 px-6  w-full mx-auto flex justify-between items-center h-[50px]">
          <Link to="/">
            <img
              src="/assets/logos/odonto-new.png"
              alt="Odontogroup"
              style={{ height: '40px' }}
            />
          </Link>
          {xl ? (
            <>
              <Navbar />
              <Link to="/quero-contratar?goTo=Comprar">
                <Button text="Contratar plano" Icon={CartIcon} />
              </Link>
            </>
          ) : (
            <button onClick={toggleMenu}>
              <MenuIcon />
            </button>
          )}
        </div>
      </header>
      <MobileMenu />
    </>
  );
}
