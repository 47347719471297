import { ReactElement } from 'react';

import { cva, VariantProps } from 'class-variance-authority';

const styles = cva(
  'mb-11 bg-cover mt-20 lg:min-h-[30px] min-h-[30px] max-[1400px]:flex max-[1400px]:flex-col',
);

interface Props extends VariantProps<typeof styles> {
  title?: string;
  href?: string;
  mainText?: string;
}

export default function Banner({ title, mainText }: Props): ReactElement {
  return (
    <section className={styles()}>
      <div className="flex justify-center">
        {title && (
          <div className="w-[40%] max-[1400px]:w-[70%] rounded-b-[36px] max-[1400px]:rounded-b-[100px] text-center bg-primary-main p-[20px]">
            <h2 className="text-primary-container2 text-[1.5rem]">{title}</h2>
          </div>
        )}
      </div>
      <div className="flex h-full">
        <h3 className="text-neutral-with text-[2.5rem] max-[1400px]:text-[1.5rem] leading-[3.75rem] max-[1400px]:leading-normal font-bold text-center mx-auto min-[1000px]:mt-[5rem] max-[999px]:mt-[20rem]">
          {mainText}
        </h3>
      </div>
    </section>
  );
}
