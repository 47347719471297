import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import styles from './quickActions.module.css';

interface Props {
  icon: string;
  text: string;
  href?: string;
  useAnchor?: boolean;
  reversed?: boolean;
  blank?: boolean;
}

export default function QuickActionButton({
  icon,
  text,
  useAnchor = false,
  reversed = false,
  blank = false,
  href = null,
}: Props): ReactElement {
  const isLink = !!href;
  const Item = isLink ? (useAnchor ? 'a' : Link) : 'div';
  const target = isLink ? (blank ? '_blank' : '_self') : undefined;
  return (
    <Item
      to={(useAnchor ? undefined : href) as any}
      href={useAnchor ? href : undefined}
      target={target}
      className={clsx(styles.actionButton, {
        [styles.reversed]: reversed,
        ['cursor-pointer']: isLink,
      })}
    >
      <img
        src={`/assets/images/icons/quick-actions/${icon}`}
        className="max-[999px]:h-[58px] h-[80px]"
      />
      <h4
        className={clsx(
          'text-primary-main text-2xl max-[1300px]:text-lg max-[999px]:text-left',
          { ['!text-neutral-with']: reversed },
        )}
      >
        {text}
      </h4>
    </Item>
  );
}
