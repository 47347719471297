import type { ReactElement } from 'react';

import Banner from '../Privacy/Banner';
import PDFViewer from './PdfViewer';
import './plans.css';

const Plan = (): ReactElement => (
  <>
    <Banner title="Planos" />
    <PDFViewer fileUrl="https://www.odontogroup.com.br/wp-content/uploads/2024/08/Lista_Planos_Portal.pdf" />
    <div style={{ marginLeft: '25px', marginBottom: '50px' }}>
      <h2 className="h2">
        Para mais detalhes sobre os planos conforme a resolução da Ans Anexo I
        da RN 509{' '}
        <a
          href="https://www.odontogroup.com.br/wp-content/uploads/2024/08/RN_509_-_ANEXO.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>clique aqui</strong>
        </a>
      </h2>
    </div>
  </>
);

export default Plan;
