import type { ReactElement } from 'react';

import { Button } from '../../components/Button';
import { ArrowRightIcon } from '../../components/icons/ArrowRightIcon';
import styles from './home.module.css';

const HealthSection = (): ReactElement => (
  <section className={styles.healthContainer}>
    <div className={styles.textDiv}>
      <img
        src="/assets/images/home/evo.png"
        alt="Logo Evo"
        className="max-[1299px]:mx-auto max-[1299px]:h-[94px] max-[1299px]:w-[271px]"
      />
      <h2 className={styles.healthTitle}>Solução COMPLETA para sua saúde.</h2>
      <Button
        tag="a"
        target="_blank"
        href="https://evosaude.com.br/"
        text="Saiba Mais"
        className="mt-10 max-[1299px]:mx-auto w-fit"
        intent="quaternary"
        Icon={ArrowRightIcon}
        invertIcon
      />
    </div>
    <div className="min-[1300px]:hidden">
      <p className="text-center text-customBig relative -bottom-10 text-primary-container2">
        #VemPraEvo
      </p>
      <img
        src="/assets/images/home/guy.jpeg"
        className="h-[827px] object-cover object-[70%_1%]"
      />
    </div>
  </section>
);

export default HealthSection;
