import type { ReactElement } from 'react';

import styles from './spaces.module.css';

interface Props {
  icon: string;
  text: string;
  href: string;
}

const SpaceCard = ({ icon, text, href }: Props): ReactElement => (
  <a className={styles.card} target="_blank" rel="noreferrer" href={href}>
    <img
      src={`/assets/images/icons/spaces/${icon}`}
      className="w-20 h-20 max-[899px]:w-16 max-[899px]:h-16"
    />
    <div className="flex flex-col min-[900px]:justify-center min-[900px]:items-center">
      <b className="font-bold text-medium-emphasis">Para você</b>
      <h3 className="text-primary-main text-2xl">{text}</h3>
    </div>
  </a>
);

export default SpaceCard;
