import { type ReactElement, useEffect, useState } from 'react';

import {
  APIProvider,
  AdvancedMarker,
  Map as GMap,
  InfoWindow,
  Pin,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';

import { Dentist } from '../..';

function DentistMarker({ dentist }: { dentist: Dentist }): ReactElement {
  const [open, setOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        position={{
          lat: +dentist.latitude,
          lng: +dentist.longitude,
        }}
        onClick={() => setOpen(o => !o)}
      >
        <Pin
          background={'#EE6C4D'}
          borderColor={'#EE6C4D'}
          glyphColor={'white'}
        />
      </AdvancedMarker>
      {open && (
        <InfoWindow
          anchor={marker}
          maxWidth={226}
          onCloseClick={() => setOpen(false)}
        >
          <div className="text-center flex flex-col gap-2">
            <h4 className="text-base text-primary-main font-[400]">
              {dentist.nomeFuncionario}
            </h4>
            <p className="text-medium-emphasis">{dentist.bairro}</p>
            <p className="text-base text-high-emphasis font-[400]">
              {dentist.telefone?.[0]?.telefone}
            </p>
            <a
              className="underline text-sm text-primary-light font-[400]"
              href="#"
            >
              Ver Site
            </a>
          </div>
        </InfoWindow>
      )}
    </>
  );
}

const apiKey = process.env.REACT_APP_GOOGLE_API_TOKEN;
const mapId = process.env.REACT_APP_GOOGLE_MAP_ID;

export default function Map({ docList }: { docList: Dentist[] }): ReactElement {
  const [center, setCenter] = useState<{ lat: number; lng: number }>(null);

  useEffect(() => {
    const siz = docList.length;
    let lat = 0;
    let lng = 0;
    for (const doc of docList) {
      lat += +doc.latitude;
      lng += +doc.longitude;
    }

    setCenter({ lat: lat / siz, lng: lng / siz });
    setTimeout(() => setCenter(null), 500);
  }, [docList]);

  return (
    <APIProvider apiKey={apiKey}>
      {/* <div className="max-[999px]:-order-1 min-[999px]:h-[700px] min-[999px]:w-[950px] w-full h-[300px]"> */}
      <div className="max-[999px]:-order-1 min-[999px]:h-[700px] w-full h-[300px]">
        <GMap
          mapId={mapId}
          defaultZoom={11}
          minZoom={5}
          center={center}
          defaultCenter={{ lat: -15.7801, lng: -47.9292 }}
          style={{ borderRadius: 10 }}
        >
          {docList.map(d => (
            <DentistMarker key={d.idFuncionario} dentist={d} />
          ))}
        </GMap>
      </div>
    </APIProvider>
  );
}
