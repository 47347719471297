import { ReactElement } from 'react';

import { useHirePlanFormStepStore } from '../../stores/useHirePlanFormStepStore';
import { BeneficiaryRegisterForm } from './steps/BeneficiaryRegisterForm';
import { DependentsForm } from './steps/DependentsForm';
import { FinancialResponsibleForm } from './steps/FinancialResponsibleForm';
import { PaymentMethodForm } from './steps/PaymentMethodForm';
import { PlanBoostForm } from './steps/PlanBoostForm';
import { useRouteLoaderData } from 'react-router-dom';
import CompanyForm from '../../pages/Companies/CompanyForm';

export function HireBussinessPlanFormStepHandler(): ReactElement {
  /* const step = useHirePlanFormStepStore(state => state.step);

  switch (step) {
    case 1: {
      return <BeneficiaryRegisterForm />;
    }

    case 2: {
      return <DependentsForm />;
    }

    case 3: {
      return <PlanBoostForm />;
    }

    case 4: {
      return <PaymentMethodForm />;
    }

    default: {
      return <BeneficiaryRegisterForm />;
    }
  } */
  return (
    <section>
      <div>
        <div>
          <CompanyForm />
        </div>
      </div>
    </section>
  );
}
