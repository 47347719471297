import type { ReactElement } from 'react';

import FaqList from '../../../components/FaqList';
import QuestionForm from './QuestionForm';

const FaqSection = (): ReactElement => (
  <section className="min-[1300px]:mt-10 min-[1300px]:mb-52">
    <div className="flex max-[1000px]:flex-col min-[1300px]:gap-32 items-start max-w-[100%] overflow-hidden">
      <div className="mix-[1300px]:pl-20 px-5 z-10">
        <FaqList />
        <div className="invisible max-[999px]:hidden">
          <p>t</p>
        </div>
      </div>
      <div className="mt-36 max-[999px]:-mt-16 min-[1000px]:max-w-[60%]">
        <div className="bg-new-blue min-[1000px]:w-[758px] min-[1000px]:h-[680px] min-[1000px]:rounded-l-xl min-[1000px]:pl-10 flex items-center w-[100vw] max-[999px]:px-5 max-[1300px]:pb-36 max-[1300px]:pt-36">
          <div className="bg-white rounded-xl p-10 max-[748px]:p-5 shadow-card min-[1001px]:w-[406px] w-full h-fit">
            <QuestionForm />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default FaqSection;
