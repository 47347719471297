import type { ReactElement } from 'react';

const StepsSection = (): ReactElement => (
  <section className="justify-evenly -mt-11">
    <div className="bg-primary-main flex flex-col justify-center p-14">
      <h3 className="text-4xl font-semibold text-neutral-with mb-2 max-[1000px]:text-2xl">
        O que queremos
      </h3>
      <p className="text-2xl text-[#E2E4FD] max-[1000px]:text-lg">
        Evoluir o cuidado à saúde com eficiência, qualidade e segurança.
      </p>
    </div>
    <div className="bg-new-blue flex flex-col justify-center p-14">
      <h3 className="text-4xl font-semibold text-primary-dark mb-2 max-[1000px]:text-2xl">
        O que faremos
      </h3>
      <p className="text-2xl text-primary-main max-[1000px]:text-lg">
        Consolidar-nos-emos como uma marca de confiança, humanizada, que oferece
        aos seus clientes uma relação duradoura com padrão de excelência,
        inovação, qualidade, prevenção e bem-estar.
      </p>
    </div>
    <div className="bg-new-green flex flex-col justify-center p-14">
      <h3 className="text-4xl font-semibold text-primary-dark mb-2 max-[1000px]:text-2xl">
        O que nos move
      </h3>
      <p className="text-2xl text-primary-main max-[1000px]:text-lg">
        Respeito nas relações, valorização de pessoas, transparência de conduta
        e compromisso social real com sustentabilidade e inovação.
      </p>
    </div>
  </section>
);

export default StepsSection;
