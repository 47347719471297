import type { ReactElement } from 'react';

const AppsSection = (): ReactElement => (
  <section className="mb-52 max-[999px]:mb-40 min-[1000px]:px-3">
    <img
      className="mx-auto max-[999px]:object-cover max-[999px]:h-[882px] min-[1000px] min-[1570px]:max-w-[1569px] min-[1000px]:rounded-3xl object-[40%] max-w-[100%] brightness-50"
      src="/assets/images/home/old.jpeg"
      alt="Sorriso"
    />
    <div className="min-[1000px]:text-center -mt-[23rem] max-[1000px]:px-5 text-left mx-auto max-[999px]:max-w-[328px] relative z-10">
      <h2 className="text-primary-container2 text-customBigger font-semibold text-center">
        Ligado em você sempre
      </h2>
      <p className="text-neutral-with min-[1000px]:text-2xl text-base max-w-[684px] mx-auto text-center">
        Baixe o aplicativo da OdondoGroup e tenha tudo na palma da sua mão
      </p>
      <div className="mt-5 flex max-[748px]:flex-col gap-6 max-[748px]:items-center mx-auto min-[748px]:justify-center">
        <a
          className="max-w-[50%]"
          href={process.env.REACT_APP_GOOGLE_PLAY}
          target="_blank"
          rel="noreferrer"
        >
          <img src="/assets/images/google-play-tag.png" alt="Tag GooglePlay" />
        </a>
        <a
          className="max-w-[50%]"
          href={process.env.REACT_APP_APPLE_STORE}
          target="_blank"
          rel="noreferrer"
        >
          <img src="/assets/images/app-store-tag.png" alt="Tag AppStore" />
        </a>
      </div>
    </div>
  </section>
);

export default AppsSection;
