interface Dados {
  email: string;
  nome: string;
  fone: string;
  valor: number;
  plano: string;
  prod_comercial: string;
  preco: number;
}

export const enviarDados = async (dados: Dados): Promise<any> => {
  console.log('dados: ', dados);
  const stage_id = process.env.REACT_APP_RD_STATION_CRM_DEAL;
  console.log('estagio: ', stage_id);
  const data = {
    distribution_settings: {
      owner: {
        id: '66031823c088270020994b5e',
        type: 'user',
      },
    },
    contacts: [
      {
        emails: [{ email: dados.email }],
        name: dados.nome,
        phones: [{ phone: '+55' + dados.fone, type: 'cellphone' }],
      },
    ],
    deal: {
      deal_stage_id: stage_id,
      name: 'Prospecção',
    },
    deal_products: [
      {
        price: dados.preco,
        name: 'Selecionado: ' + dados.prod_comercial,
      },
    ],
  };
  if (dados.valor != null && dados.plano != null) {
    const product = {
      price: dados.valor,
      name: 'Atual: ' + dados.plano,
    };
    data.deal_products.push(product);
  }
  console.log('dados: ', data);
  const url = `${process.env.REACT_APP_RD_STATION_CRM_BASE_URL}deals?token=${process.env.REACT_APP_RD_STATION_CRM_Token}`;
  console.log('url: ', url);
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Erro: ${response.statusText}`);
    }

    const responseData = await response.json();
    console.log('Resposta da API:', responseData);
    return responseData;
  } catch (error) {
    console.error('Erro ao enviar dados:', error);
  }
};
