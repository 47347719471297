import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PageHelmet } from '../../components/PageHelmet';
import VideoBanner from '../../components/VideoBanner';
import CompanyPlans from './CompanyPlans';
import FaqSection from './FaqSection';
import PlanSection from './PlanSection';
import SearchSection from './SearchSection';
import StepsSection from './StepsSection';
import TipsSection from './TipsSection';

export default function HiringPage(): ReactElement {
  const publicServer = () =>
    window.open('https://app.odontogroup.com.br/servidorpublico');
  const [desc, setDesc] = useState(false);

  const Switch = () => {
    setDesc(!desc);
  };
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const goTo = params.get('goTo');
    if (goTo) {
      const element = document.getElementById(goTo);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  useEffect(() => {
    const embedScript = document.createElement('script');
    embedScript.src =
      'https://app.acessibilizar.com.br/embed-scripts/7e4a1c6d-2511-425c-8e59-6883b3fb3714';
    embedScript.type = 'text/javascript';
    embedScript.async = true;
    embedScript.setAttribute(
      'embed-id',
      '7e4a1c6d-2511-425c-8e59-6883b3fb3714',
    );

    document.head.appendChild(embedScript);

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      document.head.removeChild(embedScript);
    };
  }, []);

  return (
    <div className="h-full min-h-[calc(100vh-549px)]">
      <PageHelmet title="Quero Contratar | OdontoGroup" />

      <VideoBanner page="hiring" />

      <section className="mb-40 max-[999px]:mb-[40px] min-[1000px]:px-10">
        <img
          className="mx-auto max-[999px]:h-[522px] max-[999px]:object-cover"
          src="/assets/images/hiring/bubbles.png"
          alt="Sorriso"
        />
        <div className="min-[1000px]:text-center -mt-32 max-[1000px]:pl-6 text-left">
          <h2 className="text-primary-container2 text-4xl font-semibold max-[999px]:text-2xl">
            Venha ser beneficiário Odontogroup
          </h2>
          <p className="text-neutral-with min-[1000px]:text-2xl text-base">
            É facinho!
          </p>
        </div>
      </section>

      <StepsSection />

      <PlanSection />
      <CompanyPlans />
      <section style={{ margin: '0 15 0 15' }}>
        <img
          className="w-full max-[748px]:h-[577px] max-[748px]:object-cover"
          alt="Sorriso"
          src="/assets/images/hiring/woman.png"
          style={{ minWidth: '1000px' }}
        />
        <div className="-mt-60 z-10 relative max-[748px]:-mb-44">
          <h2
            className="text-primary-container2 text-4xl font-semibold max-w-[818px] mb-5 max-[999px]:text-2xl"
            style={{ marginLeft: '70px' }}
          >
            Servidor público, faça o seu plano odontológico com desconto em
            folha
          </h2>
          <button
            onClick={publicServer}
            className="text-neutral-with rounded-lg border border-neutral-with text-center py-3 px-10 max-[999px]:w-full"
            style={{ marginLeft: '70px', marginBottom: '70px' }}
          >
            Saiba Mais
          </button>
        </div>
      </section>
      <SearchSection />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '30px',
        }}
      >
        {!desc && (
          <button
            className="flex justify-center items-center text-sm py-3 px-4 rounded-lg flex gap-2 focus:outline-none focus:ring-2 focus:ring-offset-2 flex items-center h-12 disabled:opacity-40 text-neutral-with bg-primary-main hover:bg-primary-dark focus:ring-primary-light focus:ring-offset-white max-[748px]:w-full disabled:bg-gray-200"
            onClick={Switch}
          >
            Prestadores Descredenciados
          </button>
        )}
      </div>
      {desc && (
        <div style={{ height: '600px' }}>
          <iframe
            className="w-full h-full border-none m-0 p-0 overflow-hidden"
            src={process.env.REACT_APP_PRESTADORES_DESCREDENCIADOS}
          ></iframe>
        </div>
      )}
      <TipsSection />
      <FaqSection />
    </div>
  );
}
