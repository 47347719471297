import type { ReactElement } from 'react';

import styles from './tips.module.css';

const TipsSection = (): ReactElement => (
  <>
    <section className="mt-32 min-[1199px]:mb-32">
      <div className={styles.container}>
        <h2 className={styles.fullText}>
          Atendimento em todos os estados do Brasil
        </h2>

        <div className="flex">
          <img
            src="/assets/images/sol/sol-mid-arms.png"
            className={styles.solImg}
          />
          <img src="/assets/images/odonto-map.png" className={styles.mapImg} />
        </div>
      </div>
    </section>
    <section>
      <img
        src="/assets/images/hiring/ginger.png"
        className="w-full max-[748px]:h-[683px] max-[748px]:object-cover object-[70%]"
      />
      <div className="-mt-52 min-[1000px]:text-center text-left max-[1547px]:pl-8">
        <h2 className="min-[1548px]:text-[2.625rem] min-[1548px]:leading-[3.75rem] text-center min-[701px]:text-[2rem] text-lg text-primary-container2 font-semibold max-[1621px]:mt-20">
          Precisa de Ajuda?
        </h2>
        <p className="text-neutral-with min-[1548px]:text-2xl text-base mt-4">
          Não encontrou sua dúvida em perguntas frequentes?
          <br />
          Fale com nosso time!
        </p>
      </div>
    </section>
  </>
);

export default TipsSection;
