import type { ReactElement } from 'react';
import ReactPlayer from 'react-player';

import { type VariantProps, cva } from 'class-variance-authority';

interface Props extends VariantProps<typeof styles> {}

const styles = cva('mt-20 mix-[1001px]:min-h-[500px]', {
  variants: {
    page: {
      // hiring: 'min-[1300px]:mb-11 lg:min-h-[927px]',
      hiring: 'min-[1000px]:mb-11 ',
      home: '',
      about: '',
    },
  },
});

const videoStyle = cva('max-[999px]:object-cover max-[999px]:object-[35%]', {
  variants: {
    page: {
      // hiring: 'max-[999px]:h-[789px]',
      hiring: 'w-full',
      home: 'w-full',
      about: 'w-full',
    },
  },
});

const VideoBanner = ({ page }: Props): ReactElement => (
  <section className={styles({ page })}>
    {page == 'about' ? (
      <ReactPlayer
        className={videoStyle({ page })}
        url={`/assets/videos/${page}.mp4`}
        controls
        width="100%"
        height="100%"
      />
    ) : (
      <video
        className={videoStyle({ page })}
        src={`/assets/videos/${page}.mov`}
        itemType="video/mov"
        muted
        loop
        autoPlay
      />
    )}
  </section>
);

export default VideoBanner;
