import { ReactElement, useState } from 'react';

import { useDentistsContext } from '..';

import { RefreshIcon } from '../../icons/RefreshIcon';

type Question = { question: string; yes: QuestionObj; no: QuestionObj };
type Answer = { answer: string };
type QuestionObj = Question | Answer;

const specialties = {
  'Urgência 24h': {
    idEspecialidade: 12,
    nomeEspecialidade: 'URGÊNCIA DIURNA',
    quantidade: 1585,
  },
  'Clínico Geral': {
    idEspecialidade: 44,
    nomeEspecialidade: 'CLÍNICO GERAL',
    quantidade: 1585,
  },
  Dentística: {
    idEspecialidade: 4,
    nomeEspecialidade: 'DENTÍSTICA',
    quantidade: 1415,
  },
  Cirurgia: {
    idEspecialidade: 8,
    nomeEspecialidade: 'CIRURGIA',
    quantidade: 957,
  },
  Periodontia: {
    idEspecialidade: 6,
    nomeEspecialidade: 'PERIODONTIA',
    quantidade: 1302,
  },
  Ortodontia: {
    idEspecialidade: 9,
    nomeEspecialidade: 'ORTODONTIA',
    quantidade: 829,
  },
  Prótese: {
    idEspecialidade: 7,
    nomeEspecialidade: 'PRÓTESE',
    quantidade: 241,
  },
  Endodontia: {
    idEspecialidade: 5,
    nomeEspecialidade: 'ENDODONTIA',
    quantidade: 938,
  },
};

const questionObj: Question = {
  question: 'Está com dor?',
  yes: {
    question: 'Há sangramento na boca?',
    yes: { answer: 'Urgência 24h' },
    no: { answer: 'Clínico Geral' },
  },
  no: {
    question: 'Alguma restauração caiu?',
    yes: { answer: 'Dentística' },
    no: {
      question: 'Precisa extrair algum dente?',
      yes: { answer: 'Cirurgia' },
      no: {
        question: 'Precisa fazer limpeza?',
        yes: { answer: 'Periodontia' },
        no: {
          question: 'Precisa colocar aparelho?',
          yes: { answer: 'Ortodontia' },
          no: {
            question: 'Precisa repor um dente perdido?',
            yes: { answer: 'Prótese' },
            no: {
              question: 'Precisa realizar um canal?',
              yes: { answer: 'Endodontia' },
              no: { answer: 'Clínico Geral' },
            },
          },
        },
      },
    },
  },
};

export default function QuestionBox(): ReactElement {
  const [current, setCurrent] = useState(questionObj);
  const [stateList, setStateList] = useState<Question[]>([]);
  const [result, setResult] = useState<string | null>(null);
  const { setSpecialty } = useDentistsContext();

  function answer(resp: 'yes' | 'no') {
    const newQuestion = current[resp];
    if ((newQuestion as Answer).answer) {
      const ans = newQuestion as Answer;
      setResult(ans.answer);
      setSpecialty(specialties[ans.answer]);
    } else {
      setStateList([...stateList, current]);
      setCurrent(newQuestion as Question);
    }
  }

  function back() {
    setCurrent(stateList.pop() as Question);
    setStateList(stateList);
  }

  function reset() {
    setCurrent(questionObj);
    setStateList([]);
    setResult(null);
  }

  return (
    <div className="bg-neutral-white border border-neutral-lighter rounded-md p-4 flex text-medium-emphasis justify-between gap-5 items-center max-[1000px]:flex-col min-[1000px]:min-w-[745px] max-[999px]:w-full max-[999px]:min-h-[143px]">
      {result ? (
        <>
          <p>
            Indicamos: <b>{result}</b>
          </p>
          <button onClick={reset} className="flex items-center gap-1">
            <RefreshIcon type="light" />
            <p className="text-[1.25rem]">Refazer</p>
          </button>
        </>
      ) : (
        <>
          <p className="max-w-[347px]">{current.question}</p>
          <button
            disabled={stateList.length === 0}
            onClick={back}
            className="max-[1000px]:font-bold font-[500] text-[1.25rem] max-[1000px]:-order-1 max-[1000px]:self-start disabled:opacity-60 disabled:cursor-default min-[1000px]:ml-auto transition-opacity"
          >
            <span className="text-[#2E8BCE]">{'<'}</span> Voltar
          </button>
          <div className="flex gap-3">
            <button
              onClick={() => answer('yes')}
              className="cursor-pointer bg-success-container p-1 rounded flex gap-1"
            >
              <img src="/assets/images/icons/check-circle.svg" />
              <p className="text-success-dark font-medium	">Sim</p>
            </button>
            <button
              onClick={() => answer('no')}
              className="cursor-pointer bg-destructive-container2 p-1 rounded flex gap-1"
            >
              <img src="/assets/images/icons/x-circle.svg" />
              <p className="text-destructive-dark font-medium	">Não</p>
            </button>
          </div>
        </>
      )}
    </div>
  );
}
