import type { ReactElement } from 'react';

import { Button } from '../../components/Button';
import { ArrowRightIcon } from '../../components/icons/ArrowRightIcon';

const InfoBox = ({ title, info, value }) => (
  <div className="rounded-lg p-3 border border-[#E2E4FD] text-center">
    <h4 className="text-2xl text-primary-main font-bold">{title}</h4>
    <h6 className="text-xl text-medium-emphasis">{info}</h6>
    <p className="text-2xl text-new-orange font-bold">{value}</p>
  </div>
);

const ChartSection = (): ReactElement => (
  <section className="w-full bg-primary-dark p-20 max-[1000px]:p-5">
    <div className="flex flex-col items-center mt-10 gap-3">
      <h3 className="text-[40px] leading-[60px] text-center text-neutral-with max-[1200px]:text-2xl max-[1200px]:max-w-[266px]">
        Índice de Desempenho da Saúde Suplementar (IDSS)
      </h3>
      <p className="text-justify mt-3 text-[#E2E4FD] text-lg max-[900px]:text-sm">
        O IDSS é um programa de avaliação das operadoras de planos de saúde que
        permite avaliar o desempenho das operadoras por meio de um conjunto de
        indicadores definidos pela própria ANS. É calculado com base nos dados
        extraídos dos sistemas de informações gerenciais da ANS ou obtidos em
        sistemas nacionais de informação em saúde. Esses dados são fornecidos
        pelas Operadoras, através de relatórios periódicos enviados para a
        Agência.
      </p>
      <p className="text-justify mt-3 text-[#E2E4FD] text-lg max-[900px]:text-sm">
        Obtenha mais informações sobre o Programa de Qualificação de Operadoras,
        <a href="https://www.gov.br/ans/pt-br/assuntos/informacoes-e-avaliacoes-de-operadoras/qualificacao-ans">
          clicando aqui.
        </a>
      </p>
    </div>
    <img src="/assets/images/transparency/info.png" />
    <div className="bg-white flex rounded-lg p-3 gap-2 justify-center flex-wrap mt-10 max-[900px]:justify-evenly">
      <InfoBox title="IDSS 2018" info="Ano Base 2017" value={0.6686} />
      <InfoBox title="IDSS 2019" info="Ano Base 2018" value={0.6965} />
      <InfoBox title="IDSS 2020" info="Ano Base 2019" value={0.7346} />
      <InfoBox title="IDSS 2021" info="Ano Base 2020" value={0.797} />
      <InfoBox title="IDSS 2022" info="Ano Base 2021" value={0.8164} />
      <InfoBox title="IDSS 2023" info="Ano Base 2022" value={0.8372} />
    </div>
    <p className="text-center mt-3 text-[#E2E4FD] text-lg max-[900px]:text-sm">
      IDSS - Índice de Desempenho da Saúde Suplementar
    </p>

    <div className="flex flex-col items-center mt-10 gap-3">
      <h3 className="text-[40px] leading-[60px] text-center text-neutral-with max-[1200px]:text-2xl max-[1200px]:max-w-[266px]">
        Quer conhecer mais detalhes?
      </h3>
      <Button
        tag="a"
        href="https://www.ans.gov.br/qualificacao_consumidor/informacoes_operadora.asp?co_operadora_param=389854&cd_processamento_param=20200101#consulta_idss"
        target="_blank"
        text="Ir para portal da ANS"
        intent="borderless"
        Icon={ArrowRightIcon}
      />
    </div>
  </section>
);

export default ChartSection;
