import type { ReactElement } from 'react';

import FaqList from '../components/FaqList';
import { PageHelmet } from '../components/PageHelmet';

const FAQPage = (): ReactElement => (
  <div className="h-full min-h-[calc(100vh-549px)] max-[1000px]:mt-20">
    <PageHelmet title="FAQ | OdontoGroup" />
    <section>
      <img src="/assets/images/faq/faq.png" className="w-full" />
    </section>
    <div className="min-[1059px]:w-[1011px] max-[1059px]:px-6 mx-auto">
      <section className="flex flex-col items-center justify-center min-[1059px]:pt-[116px] min-[1059px]:pb-[219px] py-20 ">
        <h1 className="text-[2rem] font-semibold text-primary-main text-center">
          Como podemos te ajudar?
        </h1>
        <h2 className="text-xl text-new-orange">Perguntas frequentes.</h2>
        <FaqList />
      </section>
    </div>
  </div>
);
export default FAQPage;
