import { AnnotationIcon } from '../components/icons/AnnotationIcon';
import { BriefCaseIcon } from '../components/icons/BriefCaseIcon';
import { HomeIcon } from '../components/icons/HomeIcon';
import SearchIcon2 from '../components/icons/SearchIcon2';
import SimplePhoneIcon from '../components/icons/SimplePhoneIcon';
// import { UserGroupIcon } from '../components/icons/UserGroupIcon';

export const navigation = [
  {
    name: 'Início',
    Icon: HomeIcon,
    href: '/',
  },
  // {
  //   name: 'Beneficiário',
  //   Icon: HeartIcon,
  //   href: '/beneficiario',
  // },
  {
    name: 'Quem Somos',
    Icon: BriefCaseIcon,
    href: '/quem-somos',
    subPages: [
      {
        name: 'Quem Somos',
        href: '/quem-somos',
      },
      {
        name: 'Quero Contratar',
        href: '/quero-contratar',
      },
    ],
  },
  {
    name: 'Transparência',
    Icon: SearchIcon2,
    // href: process.env.REACT_APP_TRANSPARENCIA ?? '/',
    href: '/transparencia',
  },
  {
    name: 'Ouvidoria',
    Icon: SimplePhoneIcon,
    href: '/ouvidoria',
  },
  // {
  //   name: 'Empresas',
  //   Icon: BriefCaseIcon,
  //   href: '/empresas',
  // },
  // {
  //   name: 'Empresas',
  //   Icon: BriefCaseIcon,
  //   href: '/empresas',
  // },
  // {
  //   name: 'Dentista',
  //   Icon: UserGroupIcon,
  //   href: '/dentist',
  // },
  // {
  //   name: 'Prestador de serviço',
  //   Icon: UserGroupIcon,
  //   href: '/prestador-de-serviço',
  //   subPages: [
  //     {
  //       name: 'Corretor',
  //       href: process.env.REACT_APP_CORRETOR ?? '/',
  //       //prestador-de-serviço/corretor
  //     },
  //     {
  //       name: 'Clínica',
  //       href: process.env.REACT_APP_CLINICA ?? '/',
  //       //prestador-de-serviço/clínica
  //     },
  //     {
  //       name: 'Dentistas',
  //       href: process.env.REACT_APP_DENTISTA ?? '/',
  //       //prestador-de-serviço/dentistas
  //     },
  //   ],
  // },
  {
    name: 'FAQ',
    Icon: AnnotationIcon,
    href: '/faq',
  },
  /* {
    name: 'Blog',
    Icon: SpeakerPhoneIcon,
    href: '/blog',
  }, */
];
