import { useState, useEffect, CSSProperties } from 'react';
import type { ReactElement } from 'react';
import { useTLContext } from '.';
import Stepper from './Steps';

export default function Timeline(): ReactElement {
  const { step } = useTLContext();

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1400);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1400);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const imgStyle: CSSProperties = isLargeScreen
    ? { height: '550px' }
    : { objectFit: 'cover', width: '100%', height: '100%' };
  const titleStyle: CSSProperties = isLargeScreen
    ? { position: 'absolute', marginTop: '-200px' }
    : {};

  return (
    <section className="justify-evenly mt-24 mb-10">
      <h1 className="min-[1548px]:text-[2.625rem] min-[701px]:text-[2rem] font-semibold text-lg text-center text-primary-main">
        Uma jornada repleta de sorrisos...
      </h1>

      <div className="flex mx-auto max-w-[1400px] my-24 max-[1399px]:my-12 max-[1399px]:flex-col-reverse">
        <div className="bg-new-blue p-12 min-[1400px]:rounded-l-xl flex items-center">
          <div className="w-full">
            <div style={titleStyle}>
              <h1 className="text-8xl max-[1399px]:text-6xl text-white">
                {step.title}
              </h1>
            </div>
            <div
              className="flex items-center justify-center text-justify mt-5"
              style={{ height: 'calc(100% - 50px)' }}
            >
              <p className="text-lg max-[1499px]:text-base">{step.desc}</p>
            </div>
          </div>
        </div>
        <img src={`assets/images/about/${step.img}`} style={imgStyle} />
      </div>
      <Stepper />
    </section>
  );
}
