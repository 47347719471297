import {
  type ReactElement,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import Timeline from './Timeline';

export type Step = {
  title: string;
  id: number;
  desc: string;
  img: string;
};
interface TLContext {
  steps: Step[];
  step: Step;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
}

const TLContext = createContext({} as TLContext);

export const useTLContext = (): TLContext => useContext(TLContext);

export default function TimelineSection(): ReactElement {
  const steps = useMemo<Step[]>(
    () =>
      [
        {
          title: '2000',
          desc: ' Nasce em Brasília a Odontogroup Sistema de Saúde, uma nova Operadora de Planos Odontológicos para transformar a saúde e bem-estar de todos os brasileiros.',
          img: '2000.png',
        },
        {
          title: '2004',
          desc: 'Abertura da Filial Odontogroup Maceió, Alagoas, expandido a promoção da saúde bucal por todo o Nordeste.',
          img: '2004.jpeg',
        },
        {
          title: '2008',
          desc: 'Inauguração Filial Odontogroup Recife, Pernambuco. Transformando o sorriso de milhares de Recifenses.',
          img: '2008.jpeg',
        },
        {
          title: '2011',
          desc: 'Inauguração Filial Odontogroup Palmas, Tocantins. Um compromisso crescente e contínuo com a saúde da população.',
          img: '2011.jpeg',
        },
        {
          title: '2017',
          desc: 'A Odontogroup alcança o marco de 50 mil beneficiários. Resultado de muito comprometimento e dedicação de um time de especialistas apaixonados pelo cuidado e serviços de excelência e qualidade.',
          img: '2017.jpeg',
        },
        {
          title: '2022',
          desc: 'São mais de 100 mil vidas assistidas por todo Brasil, com soluções personalizadas e completas para todos os perfis de pessoas e empresas.',
          img: '2022.jpeg',
        },
        {
          title: '2024.1',
          desc: 'Mais de 200 mil pessoas recebendo diariamente o carinho e o cuidado de um time de excelência. Mais de 20 de anos de história que consolida todo o comprometimento e dedicação Odontogroup por todo o Brasil.',
          img: '2024.jpeg',
        },
        {
          title: '2024.2',
          desc: 'Lançamento Nova Identidade Odontogroup. Mais moderna, mais tecnológica, mais perto de cada beneficiário.',
          img: '2024.png',
        },
      ].map((s, i) => ({ ...s, id: i })),
    [],
  );
  const [step, setStep] = useState<Step>(steps[0]);

  return (
    <TLContext.Provider value={{ steps, step, setStep }}>
      <Timeline />
    </TLContext.Provider>
  );
}
