import { ReactElement } from 'react';

export const MapIcon: () => ReactElement = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 26.6673L4.73733 23.0353C4.51589 22.9247 4.32963 22.7545 4.1994 22.544C4.06918 22.3335 4.00013 22.0909 4 21.8433V7.49132C4.00012 7.26413 4.05829 7.04074 4.16899 6.84235C4.27969 6.64395 4.43925 6.47714 4.63253 6.35773C4.8258 6.23831 5.04638 6.17027 5.27334 6.16005C5.5003 6.14982 5.72611 6.19776 5.92933 6.29932L12 9.33398M12 26.6673V9.33398M12 26.6673L20 22.6673M12 9.33398L20 5.33398M20 22.6673L26.0707 25.702C26.2739 25.8035 26.4997 25.8515 26.7267 25.8413C26.9536 25.831 27.1742 25.763 27.3675 25.6436C27.5608 25.5242 27.7203 25.3573 27.831 25.159C27.9417 24.9606 27.9999 24.7372 28 24.51V10.158C27.9999 9.91044 27.9308 9.66781 27.8006 9.45728C27.6704 9.24676 27.4841 9.07664 27.2627 8.96598L20 5.33398M20 22.6673V5.33398"
      stroke="#616AC2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
