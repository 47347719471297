import type { ReactElement } from 'react';

const SimplePhoneIcon: () => ReactElement = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.32409 3.73816C3.01153 4.05072 2.83594 4.47464 2.83594 4.91667V5.75C2.83594 12.6533 8.4326 18.25 15.3359 18.25H16.1693C16.6113 18.25 17.0352 18.0744 17.3478 17.7618C17.6603 17.4493 17.8359 17.0254 17.8359 16.5833V13.8508C17.836 13.6759 17.7809 13.5053 17.6786 13.3634C17.5763 13.2214 17.4319 13.1153 17.2659 13.06L13.5218 11.8117C13.3315 11.7484 13.1248 11.7559 12.9396 11.8327C12.7544 11.9096 12.6031 12.0506 12.5134 12.23L11.5718 14.1108C9.53132 13.1889 7.89704 11.5546 6.9751 9.51417L8.85594 8.5725C9.03531 8.48288 9.17636 8.33158 9.2532 8.14637C9.33004 7.96116 9.33752 7.75445 9.27427 7.56417L8.02594 3.82C7.97069 3.65413 7.86468 3.50984 7.7229 3.40754C7.58112 3.30525 7.41077 3.25013 7.23594 3.25H4.5026C4.06058 3.25 3.63665 3.42559 3.32409 3.73816Z"
      stroke="#373E43"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SimplePhoneIcon;
