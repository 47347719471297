import type { ReactElement } from 'react';

import DownloadBox from './DownloadBox';

const SurveySection = (): ReactElement => (
  <section className="w-full flex gap-10 justify-center items-center p-20 max-[1000px]:p-5 max-[1199px]:flex-col max-[1199px]:p-5">
    <div className="min-[1200px]:h-[824px] min-[1200px]:w-[694px] mb-10">
      <img
        src="/assets/images/transparency/f.png"
        className="min-[1200px]:min-h-[824px] min-[1200px]:min-w-[694px]"
      />
      <h3 className="text-primary-container2 text-customBigger max-w-[614px] -mt-[10rem] ml-3 max-[1199px]:text-2xl max-[1199px]:-mt-[5rem]">
        Cuidado frequente, resultados ascendentes
      </h3>
    </div>
    <div className="flex flex-col gap-4 max-[799px]:w-full">
      <DownloadBox
        title="Pesquisa de Satisfação 2024"
        info="Ano Base 2023"
        href="https://www.odontogroup.com.br/wp-content/uploads/2024/03/OdontoGroup-Pesquisa-IDSS-2024-Base-2023.pdf"
      />
      <DownloadBox
        title="Parecer da Auditoria Independente sobre a Pesquisa de Satisfação 2024"
        info="Ano Base 2023"
        href="https://www.odontogroup.com.br/wp-content/uploads/2024/03/Parecer-de-Auditoria_2024.pdf"
      />
      <DownloadBox
        title="Pesquisa de Satisfação 2023"
        info="Ano Base 2022"
        href="https://www.odontogroup.com.br/wp-content/uploads/2023/08/OdontoGroup-Pesquisa-IDSS-2023-Base-2022.pdf"
      />
      <DownloadBox
        title="Parecer da Auditoria Independente sobre a Pesquisa de Satisfação 2023"
        info="Ano Base 2022"
        href="https://www.odontogroup.com.br/wp-content/uploads/2023/08/Parecer-de-Auditoria_2023.pdf"
      />
      <DownloadBox
        title="Pesquisa de Satisfação 2022"
        info="Ano Base 2021"
        href="https://www.odontogroup.com.br/wp-content/uploads/2022/04/Pesquisa_Satisfacao_Beneficiarios_2022_Relatorio.pdf"
      />
      <DownloadBox
        title="Parecer da Auditoria Independente sobre a Pesquisa de Satisfação 2022"
        info="Ano Base 2021"
        href="https://www.odontogroup.com.br/wp-content/uploads/2022/04/Pesquisa_Satisfacao_Beneficiarios_2022__Parecer_de_Auditoria_Independente.pdf"
      />
    </div>
  </section>
);

export default SurveySection;
