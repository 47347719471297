import { ReactElement, ComponentProps } from 'react';

import clsx from 'clsx';

import QuickActionButton from './Btn';
interface Item {
  text: string;
  icon: string;
  useAnchor?: boolean;
  subText?: string;
  href?: string;
  reversed?: boolean;
  blank?: boolean;
}

interface Props {
  className?: ComponentProps<'section'>['className'];
  items: Item[];
}

const QuickActions = ({ className, items }: Props): ReactElement => (
  <section className={clsx('max-[748px]:block text-center px-[5%]', className)}>
    <div className="flex gap-4 mt-14 justify-center items-center max-[1369px]:flex-col">
      {items.map((item, i) => (
        <QuickActionButton
          key={i}
          text={item.text}
          icon={item.icon}
          href={item.href}
          useAnchor={item.useAnchor}
          reversed={item.reversed}
          blank={item.blank}
        />
      ))}
    </div>
  </section>
);

export default QuickActions;
